/* eslint-disable */
import * as types from './graphql';
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\nfragment Client on Client{\n  id, name, orgName, createdAt, secret, createdByUser{name, id}, description, modifiedAt}\n": types.ClientFragmentDoc,
    "\nfragment TypeOrgMapping on TypeOrgMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus},\n  entityDescription\n}": types.TypeOrgMappingFragmentDoc,
    "\nfragment TypeLocalityMapping on TypeLocalityMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus},\n  entityDescription\n}": types.TypeLocalityMappingFragmentDoc,
    "\nfragment SpecialityMapping on SpecialityMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}": types.SpecialityMappingFragmentDoc,
    "\nfragment RecordTypeMapping on RecordTypeMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}": types.RecordTypeMappingFragmentDoc,
    "\nfragment PostMapping on PostMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}": types.PostMappingFragmentDoc,
    "\nfragment LocalityMapping on LocalityMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}": types.LocalityMappingFragmentDoc,
    "\nfragment ClinicCategoryMapping on ClinicCategoryMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}": types.ClinicCategoryMappingFragmentDoc,
    "\nfragment BrickMapping on BrickMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}": types.BrickMappingFragmentDoc,
    "\nfragment OrganizationStatusMapping on OrganizationStatusMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  entityDescription,\n  names{nameRus}\n}": types.OrganizationStatusMappingFragmentDoc,
    "\nfragment PersonStatusMapping on PersonStatusMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue,\n  alternativeClientValue,\n  client{name,id},\n  entityDescription,\n  names{nameRus}\n}": types.PersonStatusMappingFragmentDoc,
    "\nfragment PersonMapping on PersonMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  entityDescription\n}": types.PersonMappingFragmentDoc,
    "\nfragment OrganizationMapping on OrganizationMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  entityDescription\n}": types.OrganizationMappingFragmentDoc,
    "\nmutation SetClient(\n  $clientId: String = null, \n  $description: String = null, \n  $name: String!,\n  $orgName: String!,\n  $secret: String = null,\n  $userId: String!) {\n    setClient(input: {\n        clientId: $clientId, \n        description: $description,\n        name: $name,\n        orgName: $orgName,\n        secret: $secret,\n        userId: $userId\n      }){\n        id\n      }\n  }\n  ": types.SetClientDocument,
    "\nmutation RemoveBrickMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeBrickMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemoveBrickMappingDocument,
    "\nmutation RemoveClinicCategoryMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeClinicCategoryMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemoveClinicCategoryMappingDocument,
    "\nmutation RemoveLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemoveLocalityMappingDocument,
    "\nmutation RemoveOrganizationStatusMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeOrganizationStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemoveOrganizationStatusMappingDocument,
    "\nmutation SetBrickMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setBrickMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...BrickMapping\n      }\n  }\n": types.SetBrickMappingDocument,
    "\nmutation SetClinicCategoryMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setClinicCategoryMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...ClinicCategoryMapping\n      }\n  }\n": types.SetClinicCategoryMappingDocument,
    "\nmutation SetLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...LocalityMapping\n      }\n  }\n": types.SetLocalityMappingDocument,
    "\nmutation SetOrganizationStatusMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setOrganizationStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...OrganizationStatusMapping\n      }\n  }\n": types.SetOrganizationStatusMappingDocument,
    "\nmutation RemovePostMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removePostMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemovePostMappingDocument,
    "\nmutation SetPostMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setPostMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...PostMapping\n      }\n  }\n": types.SetPostMappingDocument,
    "\nmutation RemoveRecordTypeMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeRecordTypeMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemoveRecordTypeMappingDocument,
    "\nmutation SetRecordTypeMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setRecordTypeMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...RecordTypeMapping\n      }\n  }\n": types.SetRecordTypeMappingDocument,
    "\nmutation RemoveSpecialityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeSpecialityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemoveSpecialityMappingDocument,
    "\nmutation SetSpecialityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setSpecialityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...SpecialityMapping\n      }\n  }\n": types.SetSpecialityMappingDocument,
    "\nmutation RemoveTypeLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeTypeLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemoveTypeLocalityMappingDocument,
    "\nmutation SetTypeLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setTypeLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...TypeLocalityMapping\n      }\n  }\n": types.SetTypeLocalityMappingDocument,
    "\nmutation RemoveTypeOrgMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeTypeOrgMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemoveTypeOrgMappingDocument,
    "\nmutation SetTypeOrgMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setTypeOrgMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...TypeOrgMapping\n      }\n  }\n": types.SetTypeOrgMappingDocument,
    "\nmutation RemovePersonStatusMapping(\n  $clientId: String!, \n  $entityId: Int!,\n  $jurisdictionId: String!) {\n    removePersonStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemovePersonStatusMappingDocument,
    "\nmutation SetPersonStatusMapping(\n  $clientId: String!, \n  $entityId: Int!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setPersonStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...PersonStatusMapping\n      }\n  }\n": types.SetPersonStatusMappingDocument,
    "\nmutation SetPersonMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setPersonMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...PersonMapping\n      }\n  }\n": types.SetPersonMappingDocument,
    "\nmutation SetOrganizationMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setOrganizationMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...OrganizationMapping\n      }\n  }\n": types.SetOrganizationMappingDocument,
    "\nmutation RemovePersonMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removePersonMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemovePersonMappingDocument,
    "\nmutation RemoveOrganizationMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeOrganizationMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n": types.RemoveOrganizationMappingDocument,
    "\nquery CLIENTS(\n  $search: String = \"\", \n  $startDate: DateTime = \"01-01-2000\", \n  $endDate: DateTime = \"01-01-3000\",\n  $take: Int = 1,\n  $skip: Int = 0,\n  $id: String = \"\",\n  $sort: [ClientSortInput!]\n) {\n    clients(where: {\n      and: [\n        {\n          name: {contains: $search},\n          and: [\n            {createdAt: {gte: $startDate}},\n            {createdAt: {lte: $endDate}}\n          ],\n          id: {contains: $id}\n        },\n      ]\n    },order: $sort,take: $take, skip: $skip){\n        items{id, name, orgName, createdAt, secret, createdByUser{name, id}, description, modifiedAt}\n        totalCount,\n    }\n}": types.ClientsDocument,
    "\nquery TypeOrgMappings($take: Int = 1, $skip: Int = 0, $where: TypeOrgMappingFilterInput!, $order: [TypeOrgMappingSortInput!]) {\n  typeOrgMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...TypeOrgMapping\n    }\n    totalCount\n  }\n}": types.TypeOrgMappingsDocument,
    "\nquery TypeLocalityMappings($take: Int = 1, $skip: Int = 0, $where: TypeLocalityMappingFilterInput!, $order: [TypeLocalityMappingSortInput!]) {\n  typeLocalityMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...TypeLocalityMapping\n    }\n    totalCount\n  }\n}": types.TypeLocalityMappingsDocument,
    "\nquery SpecialityMappings($take: Int = 1, $skip: Int = 0, $where: SpecialityMappingFilterInput!, $order: [SpecialityMappingSortInput!]) {\n  specialityMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...SpecialityMapping\n    }\n    totalCount\n  }\n}": types.SpecialityMappingsDocument,
    "\nquery recordTypeMappings($take: Int = 1, $skip: Int = 0, $where: RecordTypeMappingFilterInput!, $order: [RecordTypeMappingSortInput!]) {\n  recordTypeMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...RecordTypeMapping\n    }\n    totalCount\n  }\n}": types.RecordTypeMappingsDocument,
    "\nquery postMappings($take: Int = 1, $skip: Int = 0, $where: PostMappingFilterInput!, $order: [PostMappingSortInput!]) {\n  postMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...PostMapping\n    }\n    totalCount\n  }\n}": types.PostMappingsDocument,
    "\nquery localityMappings($take: Int = 1, $skip: Int = 0, $where: LocalityMappingFilterInput!, $order: [LocalityMappingSortInput!]) {\n  localityMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...LocalityMapping\n    }\n    totalCount\n  }\n}": types.LocalityMappingsDocument,
    "\nquery clinicCategoryMappings($take: Int = 1, $skip: Int = 0, $where: ClinicCategoryMappingFilterInput!, $order: [ClinicCategoryMappingSortInput!]) {\n  clinicCategoryMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...ClinicCategoryMapping\n    }\n    totalCount\n  }\n}": types.ClinicCategoryMappingsDocument,
    "\nquery BrickMappings($take: Int = 1, $skip: Int = 0, $where: BrickMappingFilterInput!, $order: [BrickMappingSortInput!]) {\n  brickMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...BrickMapping\n    }\n    totalCount\n  }\n}": types.BrickMappingsDocument,
    "\nquery PersonStatusMappings($take: Int = 1, $skip: Int = 0, $where: PersonStatusMappingFilterInput!, $order: [PersonStatusMappingSortInput!]) {\n  personStatusMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...PersonStatusMapping\n    }\n    totalCount\n  }\n}": types.PersonStatusMappingsDocument,
    "\nquery OrganizationStatusMappings($take: Int = 1, $skip: Int = 0, $where: OrganizationStatusMappingFilterInput!, $order: [OrganizationStatusMappingSortInput!]) {\n  organizationStatusMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...OrganizationStatusMapping\n    }\n    totalCount\n  }\n}": types.OrganizationStatusMappingsDocument,
    "\nquery PersonMappings($take: Int = 1, $skip: Int = 0, $where: PersonMappingFilterInput!, $order: [PersonMappingSortInput!]) {\n  personMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...PersonMapping\n    }\n    totalCount\n  }\n}": types.PersonMappingsDocument,
    "\nquery OrganizationMappings($take: Int = 1, $skip: Int = 0, $where: OrganizationMappingFilterInput!, $order: [OrganizationMappingSortInput!]) {\n  organizationMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...OrganizationMapping\n    }\n    totalCount\n  }\n}": types.OrganizationMappingsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment Client on Client{\n  id, name, orgName, createdAt, secret, createdByUser{name, id}, description, modifiedAt}\n"): (typeof documents)["\nfragment Client on Client{\n  id, name, orgName, createdAt, secret, createdByUser{name, id}, description, modifiedAt}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment TypeOrgMapping on TypeOrgMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus},\n  entityDescription\n}"): (typeof documents)["\nfragment TypeOrgMapping on TypeOrgMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus},\n  entityDescription\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment TypeLocalityMapping on TypeLocalityMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus},\n  entityDescription\n}"): (typeof documents)["\nfragment TypeLocalityMapping on TypeLocalityMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus},\n  entityDescription\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment SpecialityMapping on SpecialityMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"): (typeof documents)["\nfragment SpecialityMapping on SpecialityMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment RecordTypeMapping on RecordTypeMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"): (typeof documents)["\nfragment RecordTypeMapping on RecordTypeMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment PostMapping on PostMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"): (typeof documents)["\nfragment PostMapping on PostMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment LocalityMapping on LocalityMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"): (typeof documents)["\nfragment LocalityMapping on LocalityMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment ClinicCategoryMapping on ClinicCategoryMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"): (typeof documents)["\nfragment ClinicCategoryMapping on ClinicCategoryMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment BrickMapping on BrickMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"): (typeof documents)["\nfragment BrickMapping on BrickMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  names{nameRus}\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment OrganizationStatusMapping on OrganizationStatusMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  entityDescription,\n  names{nameRus}\n}"): (typeof documents)["\nfragment OrganizationStatusMapping on OrganizationStatusMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  entityDescription,\n  names{nameRus}\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment PersonStatusMapping on PersonStatusMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue,\n  alternativeClientValue,\n  client{name,id},\n  entityDescription,\n  names{nameRus}\n}"): (typeof documents)["\nfragment PersonStatusMapping on PersonStatusMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue,\n  alternativeClientValue,\n  client{name,id},\n  entityDescription,\n  names{nameRus}\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment PersonMapping on PersonMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  entityDescription\n}"): (typeof documents)["\nfragment PersonMapping on PersonMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  entityDescription\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nfragment OrganizationMapping on OrganizationMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  entityDescription\n}"): (typeof documents)["\nfragment OrganizationMapping on OrganizationMapping {\n  id,\n  entityId,\n  createdAt,\n  clientId,\n  clientValue: clietnValue\n  alternativeClientValue,\n  client{name,id},\n  entityDescription\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetClient(\n  $clientId: String = null, \n  $description: String = null, \n  $name: String!,\n  $orgName: String!,\n  $secret: String = null,\n  $userId: String!) {\n    setClient(input: {\n        clientId: $clientId, \n        description: $description,\n        name: $name,\n        orgName: $orgName,\n        secret: $secret,\n        userId: $userId\n      }){\n        id\n      }\n  }\n  "): (typeof documents)["\nmutation SetClient(\n  $clientId: String = null, \n  $description: String = null, \n  $name: String!,\n  $orgName: String!,\n  $secret: String = null,\n  $userId: String!) {\n    setClient(input: {\n        clientId: $clientId, \n        description: $description,\n        name: $name,\n        orgName: $orgName,\n        secret: $secret,\n        userId: $userId\n      }){\n        id\n      }\n  }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemoveBrickMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeBrickMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemoveBrickMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeBrickMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemoveClinicCategoryMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeClinicCategoryMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemoveClinicCategoryMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeClinicCategoryMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemoveLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemoveLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemoveOrganizationStatusMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeOrganizationStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemoveOrganizationStatusMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeOrganizationStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetBrickMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setBrickMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...BrickMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetBrickMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setBrickMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...BrickMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetClinicCategoryMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setClinicCategoryMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...ClinicCategoryMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetClinicCategoryMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setClinicCategoryMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...ClinicCategoryMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...LocalityMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...LocalityMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetOrganizationStatusMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setOrganizationStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...OrganizationStatusMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetOrganizationStatusMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setOrganizationStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...OrganizationStatusMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemovePostMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removePostMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemovePostMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removePostMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetPostMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setPostMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...PostMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetPostMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setPostMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...PostMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemoveRecordTypeMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeRecordTypeMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemoveRecordTypeMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeRecordTypeMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetRecordTypeMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setRecordTypeMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...RecordTypeMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetRecordTypeMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setRecordTypeMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...RecordTypeMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemoveSpecialityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeSpecialityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemoveSpecialityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeSpecialityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetSpecialityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setSpecialityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...SpecialityMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetSpecialityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setSpecialityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...SpecialityMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemoveTypeLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeTypeLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemoveTypeLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeTypeLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetTypeLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setTypeLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...TypeLocalityMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetTypeLocalityMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setTypeLocalityMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...TypeLocalityMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemoveTypeOrgMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeTypeOrgMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemoveTypeOrgMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeTypeOrgMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetTypeOrgMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setTypeOrgMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...TypeOrgMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetTypeOrgMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setTypeOrgMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...TypeOrgMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemovePersonStatusMapping(\n  $clientId: String!, \n  $entityId: Int!,\n  $jurisdictionId: String!) {\n    removePersonStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemovePersonStatusMapping(\n  $clientId: String!, \n  $entityId: Int!,\n  $jurisdictionId: String!) {\n    removePersonStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetPersonStatusMapping(\n  $clientId: String!, \n  $entityId: Int!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setPersonStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...PersonStatusMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetPersonStatusMapping(\n  $clientId: String!, \n  $entityId: Int!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setPersonStatusMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...PersonStatusMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetPersonMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setPersonMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...PersonMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetPersonMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setPersonMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...PersonMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SetOrganizationMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setOrganizationMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...OrganizationMapping\n      }\n  }\n"): (typeof documents)["\nmutation SetOrganizationMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $alternativeClientValue: String = null,\n  $clientValue: String!,\n  $entityDescription: String = null,\n  $jurisdictionId: String!) {\n    setOrganizationMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        alternativeClientValue: $alternativeClientValue,\n        clientValue: $clientValue,\n        entityDescription: $entityDescription,\n        jurisdictionId: $jurisdictionId\n      }){\n        ...OrganizationMapping\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemovePersonMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removePersonMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemovePersonMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removePersonMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation RemoveOrganizationMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeOrganizationMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"): (typeof documents)["\nmutation RemoveOrganizationMapping(\n  $clientId: String!, \n  $entityId: String!,\n  $jurisdictionId: String!) {\n    removeOrganizationMapping(input: {\n        clientId: $clientId, \n        entityId: $entityId,\n        jurisdictionId: $jurisdictionId\n      }){\n        id\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery CLIENTS(\n  $search: String = \"\", \n  $startDate: DateTime = \"01-01-2000\", \n  $endDate: DateTime = \"01-01-3000\",\n  $take: Int = 1,\n  $skip: Int = 0,\n  $id: String = \"\",\n  $sort: [ClientSortInput!]\n) {\n    clients(where: {\n      and: [\n        {\n          name: {contains: $search},\n          and: [\n            {createdAt: {gte: $startDate}},\n            {createdAt: {lte: $endDate}}\n          ],\n          id: {contains: $id}\n        },\n      ]\n    },order: $sort,take: $take, skip: $skip){\n        items{id, name, orgName, createdAt, secret, createdByUser{name, id}, description, modifiedAt}\n        totalCount,\n    }\n}"): (typeof documents)["\nquery CLIENTS(\n  $search: String = \"\", \n  $startDate: DateTime = \"01-01-2000\", \n  $endDate: DateTime = \"01-01-3000\",\n  $take: Int = 1,\n  $skip: Int = 0,\n  $id: String = \"\",\n  $sort: [ClientSortInput!]\n) {\n    clients(where: {\n      and: [\n        {\n          name: {contains: $search},\n          and: [\n            {createdAt: {gte: $startDate}},\n            {createdAt: {lte: $endDate}}\n          ],\n          id: {contains: $id}\n        },\n      ]\n    },order: $sort,take: $take, skip: $skip){\n        items{id, name, orgName, createdAt, secret, createdByUser{name, id}, description, modifiedAt}\n        totalCount,\n    }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery TypeOrgMappings($take: Int = 1, $skip: Int = 0, $where: TypeOrgMappingFilterInput!, $order: [TypeOrgMappingSortInput!]) {\n  typeOrgMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...TypeOrgMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery TypeOrgMappings($take: Int = 1, $skip: Int = 0, $where: TypeOrgMappingFilterInput!, $order: [TypeOrgMappingSortInput!]) {\n  typeOrgMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...TypeOrgMapping\n    }\n    totalCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery TypeLocalityMappings($take: Int = 1, $skip: Int = 0, $where: TypeLocalityMappingFilterInput!, $order: [TypeLocalityMappingSortInput!]) {\n  typeLocalityMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...TypeLocalityMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery TypeLocalityMappings($take: Int = 1, $skip: Int = 0, $where: TypeLocalityMappingFilterInput!, $order: [TypeLocalityMappingSortInput!]) {\n  typeLocalityMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...TypeLocalityMapping\n    }\n    totalCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery SpecialityMappings($take: Int = 1, $skip: Int = 0, $where: SpecialityMappingFilterInput!, $order: [SpecialityMappingSortInput!]) {\n  specialityMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...SpecialityMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery SpecialityMappings($take: Int = 1, $skip: Int = 0, $where: SpecialityMappingFilterInput!, $order: [SpecialityMappingSortInput!]) {\n  specialityMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...SpecialityMapping\n    }\n    totalCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery recordTypeMappings($take: Int = 1, $skip: Int = 0, $where: RecordTypeMappingFilterInput!, $order: [RecordTypeMappingSortInput!]) {\n  recordTypeMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...RecordTypeMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery recordTypeMappings($take: Int = 1, $skip: Int = 0, $where: RecordTypeMappingFilterInput!, $order: [RecordTypeMappingSortInput!]) {\n  recordTypeMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...RecordTypeMapping\n    }\n    totalCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery postMappings($take: Int = 1, $skip: Int = 0, $where: PostMappingFilterInput!, $order: [PostMappingSortInput!]) {\n  postMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...PostMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery postMappings($take: Int = 1, $skip: Int = 0, $where: PostMappingFilterInput!, $order: [PostMappingSortInput!]) {\n  postMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...PostMapping\n    }\n    totalCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery localityMappings($take: Int = 1, $skip: Int = 0, $where: LocalityMappingFilterInput!, $order: [LocalityMappingSortInput!]) {\n  localityMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...LocalityMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery localityMappings($take: Int = 1, $skip: Int = 0, $where: LocalityMappingFilterInput!, $order: [LocalityMappingSortInput!]) {\n  localityMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...LocalityMapping\n    }\n    totalCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery clinicCategoryMappings($take: Int = 1, $skip: Int = 0, $where: ClinicCategoryMappingFilterInput!, $order: [ClinicCategoryMappingSortInput!]) {\n  clinicCategoryMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...ClinicCategoryMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery clinicCategoryMappings($take: Int = 1, $skip: Int = 0, $where: ClinicCategoryMappingFilterInput!, $order: [ClinicCategoryMappingSortInput!]) {\n  clinicCategoryMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...ClinicCategoryMapping\n    }\n    totalCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery BrickMappings($take: Int = 1, $skip: Int = 0, $where: BrickMappingFilterInput!, $order: [BrickMappingSortInput!]) {\n  brickMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...BrickMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery BrickMappings($take: Int = 1, $skip: Int = 0, $where: BrickMappingFilterInput!, $order: [BrickMappingSortInput!]) {\n  brickMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...BrickMapping\n    }\n    totalCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery PersonStatusMappings($take: Int = 1, $skip: Int = 0, $where: PersonStatusMappingFilterInput!, $order: [PersonStatusMappingSortInput!]) {\n  personStatusMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...PersonStatusMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery PersonStatusMappings($take: Int = 1, $skip: Int = 0, $where: PersonStatusMappingFilterInput!, $order: [PersonStatusMappingSortInput!]) {\n  personStatusMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...PersonStatusMapping\n    }\n    totalCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery OrganizationStatusMappings($take: Int = 1, $skip: Int = 0, $where: OrganizationStatusMappingFilterInput!, $order: [OrganizationStatusMappingSortInput!]) {\n  organizationStatusMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...OrganizationStatusMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery OrganizationStatusMappings($take: Int = 1, $skip: Int = 0, $where: OrganizationStatusMappingFilterInput!, $order: [OrganizationStatusMappingSortInput!]) {\n  organizationStatusMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...OrganizationStatusMapping\n    }\n    totalCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery PersonMappings($take: Int = 1, $skip: Int = 0, $where: PersonMappingFilterInput!, $order: [PersonMappingSortInput!]) {\n  personMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...PersonMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery PersonMappings($take: Int = 1, $skip: Int = 0, $where: PersonMappingFilterInput!, $order: [PersonMappingSortInput!]) {\n  personMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...PersonMapping\n    }\n    totalCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery OrganizationMappings($take: Int = 1, $skip: Int = 0, $where: OrganizationMappingFilterInput!, $order: [OrganizationMappingSortInput!]) {\n  organizationMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...OrganizationMapping\n    }\n    totalCount\n  }\n}"): (typeof documents)["\nquery OrganizationMappings($take: Int = 1, $skip: Int = 0, $where: OrganizationMappingFilterInput!, $order: [OrganizationMappingSortInput!]) {\n  organizationMappings(\n    where: $where\n    take: $take\n    skip: $skip, \n\torder: $order\n  ) {\n    items {\n      ...OrganizationMapping\n    }\n    totalCount\n  }\n}"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;
