import {makeAutoObservable} from "mobx";
import {getApiConfiguration, IExpanded, toDateTimeString} from "../../../../../core";
import {PersonDoblesApi, PersonDto,} from "../../../../../services/management";
import {JobsTableStore} from "../jobs/JobsTableStore";
import {PersonItemStore} from "../../PersonItemStore";
import {DoubleTableStore} from "./DoubleTableStore";

export class DoubleItemStore implements IExpanded {
    readonly _store: DoubleTableStore;
    readonly _dto: PersonDto & { doubleId: string | null, date: Date | null };
    readonly _edit: boolean;
    id: string;
    externalId: string;
    private _expand: boolean = false;
    processing: boolean = false;
    jobDouble: JobsTableStore;
    jobAntiDouble: JobsTableStore;
    addDialogState: boolean = false;
    deleteDialogState: boolean = false;

    constructor(store: DoubleTableStore, dto: PersonDto & { doubleId: string | null, date: Date | null }, edit: boolean = false) {
        this._store = store;
        this._dto = dto;
        this._edit = edit;
        this.id = dto.id;
        this.externalId = dto.externalId ?? '';
        const doubleItemStore = new PersonItemStore(store.store._store, dto)
        this.jobDouble = new JobsTableStore(doubleItemStore);
        this.jobAntiDouble = new JobsTableStore(store.store);

        makeAutoObservable(this);
    }

    get expand() {
        return this._expand;
    }

    get createdDate(): string {
        if (!this._dto.date) return '';
        return toDateTimeString(this._dto.date);
    }

    openDetails() {
        this.jobDouble.pull();
        this.jobAntiDouble.pull();
    }

    get stateDouble() {
        if (!!this._edit) {
            return {
                color: 'orange',
                title: 'Не добавлено'
            }
        }
        return {
            color: 'green',
            title: 'Добавлено'
        }
    }

    set expand(value: boolean) {
        this._expand = value;
    }

    async submitAntiDoble(): Promise<void> {
        this.processing = true;
        const api: PersonDoblesApi = new PersonDoblesApi(getApiConfiguration());
        try {
            await api.createPersonDoble({
                setPersonDobleDto: {
                    antidobleId: this._store.store.id,
                    dobleId: this._dto.id,
                }
            });
            const updatePerson = await this._store.store._store.getPersonPage(this._store.store.id);
            if(updatePerson) this._store.store.update(updatePerson?._dto);

        } finally {
            this._store.items = this._store.items.filter(x => x.id !== this._dto.id);
            this._store.pull();
            this.processing = false;
            this.addDialogState = false;
        }
    }

    async removeDoble(itemId?: string, item?: string, doble?: boolean) {
        this.processing = true;
        try {
            const api: PersonDoblesApi = new PersonDoblesApi(getApiConfiguration());
            await api.deletePersonDoble({
                id: this._dto.doubleId!
            });
            this.deleteDialogState = false;
            const updatePerson = await this._store.store._store.getPersonPage(this._store.store.id);
            if(updatePerson)this._store.store.update(updatePerson?._dto);
        } finally {
            this._store.pull();
            this.processing = false;
        }
    }
}
