import {observer} from "mobx-react";
import React from "react";

import {useTranslation} from "react-i18next";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {DefaultSelect} from "../../../../../components/shared/components/DefaultSelect";
import {CreateButtonsGroup, FormRoot, FormRow} from "../../../../../components";
import {DefaultFormModalStore} from "../../../../../components/shared/DefaultFormModalStore";
import {DefaultValue} from "../../../../../components/shared/components/DefaultValue";

interface Props {
    store: DefaultFormModalStore<{
        value: DefaultValueStore<string>,
        entity: DefaultSelectStore<any>
    }>;
    entityName: string;
}

export const MappingCreator = observer(({store, entityName}: Props) => {
    const {t} = useTranslation();

    return (
        <FormRoot>
            <FormRow>
                <DefaultSelect
                    autoFocus
                    store={store.fields.entity}
                    title={entityName}
                    style={{marginRight: 20}}
                    testid="mappingCreator-entity"
                    nameExp={store.fields.entity.nameExp}/>
            </FormRow>
            <FormRow>
                <DefaultValue store={store.fields.value}
                              style={{marginRight: 20}}
                              disabled={store.processing}
                              title={t("clientPage.mappings.table.clientValue")}/>
            </FormRow>

            <CreateButtonsGroup onCancel={() => store.Open = false}
                                isCancelBtnDisabled={store.processing}
                                onSave={async () => await store.submit()}
                                isSaveBtnDisabled={store.processing || !store.valid}
                                testid="mappingCreator"/>
        </FormRoot>
    );
});
