import {observer} from "mobx-react";
import React, {useEffect} from "react";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Box, Checkbox, FormControlLabel} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    TypeOrganizationTableStore
} from "../../../../../stores/management/clients/client/tabs/bounds/items/TypeOrg/TypeOrganizationTableStore";
import {MbEmptyComponent} from "../../../../../components/MbEmptyComponent";
import {
    TypeOrganizationStore
} from "../../../../../stores/management/clients/client/tabs/bounds/items/TypeOrg/TypeOrganizationStore";
import {AuthorizePermission, PageBody, ParamsButtonGroup, Progress} from "../../../../../components";
import {JurisdictionData} from "../../../../../stores/authorization/AuthorizationStore";
import {AllPermissions} from "../../../../../core/const";

const StyledTreeItem = observer((props: { store: TypeOrganizationStore }) => {
    const {t} = useTranslation();
    useEffect(() => {
        if (props.store.isEmpty) props.store.pull()
    }, [])

    return (
        <TreeItem
            key={props.store.id}
            nodeId={props.store.id}
            onClick={async (e: any) => {
                props.store.expand = !props.store.expand
                if (props.store.expand)
                    await props.store.pull()
            }}
            label={
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.store.selected}
                            style={{ marginLeft: 5 }}
                            indeterminate={props.store.indeterminate}
                            onChange={async event => {
                                props.store.setSelected(event.target.checked)
                            }}
                            onClick={e => e.stopPropagation()}
                        />
                    }
                    label={<div onClick={e => e.stopPropagation()}>{props.store.name ? props.store.name : t('clientPage.allTypeOrgs')}</div>}
                    key={props.store.id}
                />
            }
        >
            <TreeItem nodeId={props.store.id + "load"} />
            {Array.isArray(props.store._children) && !props.store.loading
                ? props.store._children.map(node =>
                    <StyledTreeItem store={node} />)
                : null}
            <Progress store={props.store}/>
        </TreeItem>
    )
});


interface Props {
    store: TypeOrganizationTableStore;
    style?: React.CSSProperties
}

export const TypeOrganizationTable = observer(({store, style}: Props) => {
    const {t} = useTranslation();
    return (
        <Box sx={{minHeight: '250px', display: 'grid'}}>
            <PageBody style={{display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
                <MbEmptyComponent
                    isEmpty={store.typeOrgs.length == 0 && !store.loading}
                    position={'relative'}
                    size={150}>
                    <TreeView
                        aria-label="customized"
                        defaultCollapseIcon={<ExpandMoreIcon/>}
                        defaultExpandIcon={<ChevronRightIcon/>}
                        sx={{maxHeight: '500px', flexGrow: 1, maxWidth: '100%', width: '100%'}}
                        defaultExpanded={[JurisdictionData.organizationTypes.emptyId]}
                    >
                        {
                            store.typeOrgs?.map(x =>
                                <StyledTreeItem store={x}/>
                            )
                        }
                    </TreeView>
                    {!store.typeOrgs?.length && <Box
                        sx={{
                            flexGrow: 1,
                            py: 3,
                        }}
                    >
                        <Progress store={store}/>
                    </Box>
                    }
                </MbEmptyComponent>
            </PageBody>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 'auto'
                }}
            >
                <AuthorizePermission permissions={AllPermissions.client.update}>
                    <ParamsButtonGroup
                        testid="localityTableBound"
                        onCancel={() => store.reset()}
                        onSave={async () => await store.save()}
                        isCancelBtnDisabled={store.processing || store.equals}
                        isSaveBtnDisabled={store.processing || store.equals}
                    />
                </AuthorizePermission>

            </Box>
        </Box>
    );
});
