import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IExpanded, ILoad, IProcessing, ISelected} from "../../../../../../../../core";
import {
    ClientTypeOrgsApi,
    GetBoundTypeOrgsRequest,
    GetTypeOrgsRequest,
    QueryType,
    TypeOrgDto,
    TypeOrgsApi
} from "../../../../../../../../services/management";
import {TypeOrganizationStore} from "./TypeOrganizationStore";
import {BoundsStore} from "../BoundsStore";
import {ClientFragment} from "../../../../../../../../gql/graphql";

type TypeOrgNode = TypeOrgDto & ISelected & IExpanded & { children: TypeOrgNode[]}

export class TypeOrganizationTableStore implements IProcessing, ILoad, IExpanded {
    readonly store: BoundsStore;
    readonly _dto: ClientFragment;
    processing: boolean = false;
    loading: boolean = false;
    private _search: string = "";
    private _expand: boolean = false;
    count: number = 0;

    saveTypeOrgs: TypeOrgDto[] = [];

    defaultTree: TypeOrgNode[] = [];

    typeOrgs: TypeOrganizationStore[] = [];

    constructor(store: BoundsStore, dto: ClientFragment) {
        this.store = store;
        this._dto = dto;
        makeAutoObservable(this);
    }

    get expand(): boolean{
        return this._expand;
    }

    set expand(value: boolean){
        this._expand = value;
        if(this.expand)
            this.loadData()
    }

    get search() {
        return this._search
    }

    get saveTypeOrgsIds(): string[] {
        return this.saveTypeOrgs.map(x => x.id);
    }

    get newSelectedItems() {
        return this.selectedTypeOrgsIds.filter(x => this.saveTypeOrgsIds.indexOf(x) == -1);
    }

    get newUnSelectedItems() {
        return this.saveTypeOrgsIds.filter(x => this.selectedTypeOrgsIds.indexOf(x) == -1)
    }

    get equals() {
        return this.newSelectedItems.length == 0 && this.newUnSelectedItems.length == 0
    }

    get selectedTypeOrgsIds(): string[] {
        return this.typeOrgs.map(x => x.selectedChildren).reduce(function (x, y) {
            return x.concat(y);
        }, []).concat(this.typeOrgs.filter(x => x.selected)).map(x => x.id)
    }

    get allTypeOrgVM(): TypeOrganizationStore[] {
        return this.typeOrgs.map(x => x.allChildren).reduce(function (x, y) {
            return x.concat(y);
        }, []).concat(this.typeOrgs)
    }

    async loadData(): Promise<void> {
        if (this.typeOrgs.length > 0)
            return;
        this.loading = true;
        await this.getCount();
        await this.loadSelected(this.count);
        if (!!this.saveTypeOrgs?.length) {
            this.defaultTree = await this.getTreeTypeOrgs(this.saveTypeOrgs)
            this.firstInit();
        } else {
            await this.pull();
        }
        this.loading = false;
    }

    async setSearch(value: string): Promise<void> {
        this._search = value;
        await this.pull();
    }

    async loadSelected(size?: number): Promise<void> {
        const filters = {
            page: 1,
            boundId: this.store.id,
            size: size || 1000,
            jurisdictionId: this.store.jurisdiction.id
        } as GetBoundTypeOrgsRequest;
        const api: ClientTypeOrgsApi = new ClientTypeOrgsApi(getApiConfiguration());
        this.saveTypeOrgs = (await api.getBoundTypeOrgs(filters)).map(x => x.typeOrg);
    }

    emptyParent(parentId: string): Promise<TypeOrgDto> {
        const api: TypeOrgsApi = new TypeOrgsApi(getApiConfiguration());
        const res = api.getTypeOrgById({id: parentId})
        return res
    }

    async getTreeTypeOrgs(typeOrgs: TypeOrgDto[]): Promise<TypeOrgNode[]> {
        if (typeOrgs.length == 0)
            return [];

        let tree: TypeOrgNode[] = [];

        function getNodeById(nodes: TypeOrgNode, id: string) {
            if (nodes.id === id) {
                return nodes;
            } else if (Array.isArray(nodes.children)) {
                let result = null;
                nodes.children.forEach(node => {
                    if (!!getNodeById(node, id)) {
                        result = getNodeById(node, id);
                    }
                });
                return result;
            }
            return null;
        }

        for (let i = 0; i < typeOrgs.length; i++) {
            let typeOrg = typeOrgs[i] as TypeOrgNode;
            if (this.saveTypeOrgsIds.indexOf(typeOrg.id) > -1)
                typeOrg.selected = true;

            let hasParent: boolean = !!typeOrg.parentId && typeOrg.parentId != typeOrg.id;

            while (hasParent) {
                let parent = tree[0] ? getNodeById(tree[0], typeOrg.parentId!) : null;
                if (parent) {
                    if (!!parent?.children) {
                        parent.children.push(typeOrg)
                    } else {
                        parent.children = [typeOrg]
                    }
                    break;
                } else {
                    if(!typeOrg.parent && typeOrg.parentId) {
                        let newParent = null;
                        newParent = await this.emptyParent(typeOrg.parentId)
                        if(newParent) {
                            parent = newParent as TypeOrgNode
                            parent.children = [typeOrg]
                            typeOrg = parent;
                        }
                    } else {
                        parent = typeOrg.parent as TypeOrgNode;
                        parent.children = [typeOrg]
                        typeOrg = parent;
                    }
                    hasParent = !!typeOrg.parentId && typeOrg.parentId != typeOrg.id;
                }
            }

            if (!i)
                tree.push(typeOrg as TypeOrgNode);
        }
        return tree;
    }

    firstInit() {
        function getTypeOrgVM(node: TypeOrgNode, parent?: TypeOrganizationStore): TypeOrganizationStore {
            let vm = new TypeOrganizationStore(node, parent)
            vm.setSelected(node.selected, false, false)
            vm._children = node.children?.map(x => getTypeOrgVM(x, vm)) ?? undefined
            return vm;
        }

        this.typeOrgs = this.defaultTree.map(x => getTypeOrgVM(x, undefined));
    }

    reset() {
        this.allTypeOrgVM.forEach(x => x.setSelected(this.saveTypeOrgsIds.indexOf(x.id) > -1, false, false))
    }

    async getCount(): Promise<void> {
        const api: TypeOrgsApi = new TypeOrgsApi(getApiConfiguration());
        const count = await api.getTypeOrgsCount();
        this.count = count.count
    }

    async pull(): Promise<void> {
        const config = getApiConfiguration();
        config.headers!['Jurisdiction'] = this.store.jurisdiction.id!;
        this.processing = true;
        const api: TypeOrgsApi = new TypeOrgsApi(config);
        this.processing = true;
        const filters = {
            queryType: QueryType.Tree,
            parentId: undefined,
            order: "ascending",
            page: 1,
            size: 100,
        } as GetTypeOrgsRequest;

        try {
            const items = await api.getTypeOrgs(filters);
            this.typeOrgs = items.map(x => new TypeOrganizationStore(x, undefined))
            this.processing = false;
        } catch (e) {
            this.processing = false;
        }
    };

    async save(): Promise<void> {
        this.processing = true;
        try {
            let newItem = this.saveTypeOrgs;
            if (this.newUnSelectedItems.length > 0) {
                await new ClientTypeOrgsApi(getApiConfiguration()).removeBoundTypeOrgs({
                    boundId: this._dto.id,
                    requestBody: this.newUnSelectedItems
                })
                newItem = newItem.filter(x => this.newUnSelectedItems.indexOf(x.id) == -1);
            }

            if (this.newSelectedItems.length > 0) {
                let result = await new ClientTypeOrgsApi(getApiConfiguration()).setBoundTypeOrgs({
                    boundId: this._dto.id,
                    requestBody: this.newSelectedItems
                })
                newItem = newItem.concat(result.map(x => x.typeOrg))
            }

            this.saveTypeOrgs = newItem;

            this.processing = false;

        } catch (e) {
            this.processing = false;
        }
    }
}

