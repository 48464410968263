import {graphql} from "../gql/gql"

export const CLIENTS_QUERY = graphql(`
query CLIENTS(
  $search: String = "", 
  $startDate: DateTime = "01-01-2000", 
  $endDate: DateTime = "01-01-3000",
  $take: Int = 1,
  $skip: Int = 0,
  $id: String = "",
  $sort: [ClientSortInput!]
) {
    clients(where: {
      and: [
        {
          name: {contains: $search},
          and: [
            {createdAt: {gte: $startDate}},
            {createdAt: {lte: $endDate}}
          ],
          id: {contains: $id}
        },
      ]
    },order: $sort,take: $take, skip: $skip){
        items{id, name, orgName, createdAt, secret, createdByUser{name, id}, description, modifiedAt}
        totalCount,
    }
}`)


export const TYPE_ORG_MAPPING_QUERY = graphql(`
query TypeOrgMappings($take: Int = 1, $skip: Int = 0, $where: TypeOrgMappingFilterInput!, $order: [TypeOrgMappingSortInput!]) {
  typeOrgMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...TypeOrgMapping
    }
    totalCount
  }
}`)

export const TYPE_LOCALITY_MAPPING_QUERY = graphql(`
query TypeLocalityMappings($take: Int = 1, $skip: Int = 0, $where: TypeLocalityMappingFilterInput!, $order: [TypeLocalityMappingSortInput!]) {
  typeLocalityMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...TypeLocalityMapping
    }
    totalCount
  }
}`)

export const SPECIALITY_MAPPING_QUERY = graphql(`
query SpecialityMappings($take: Int = 1, $skip: Int = 0, $where: SpecialityMappingFilterInput!, $order: [SpecialityMappingSortInput!]) {
  specialityMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...SpecialityMapping
    }
    totalCount
  }
}`)

export const RECORD_TYPE_MAPPING_QUERY = graphql(`
query recordTypeMappings($take: Int = 1, $skip: Int = 0, $where: RecordTypeMappingFilterInput!, $order: [RecordTypeMappingSortInput!]) {
  recordTypeMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...RecordTypeMapping
    }
    totalCount
  }
}`)

export const POST_MAPPING_QUERY = graphql(`
query postMappings($take: Int = 1, $skip: Int = 0, $where: PostMappingFilterInput!, $order: [PostMappingSortInput!]) {
  postMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...PostMapping
    }
    totalCount
  }
}`)

export const LOCALITY_MAPPING_QUERY = graphql(`
query localityMappings($take: Int = 1, $skip: Int = 0, $where: LocalityMappingFilterInput!, $order: [LocalityMappingSortInput!]) {
  localityMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...LocalityMapping
    }
    totalCount
  }
}`)

export const CLINIC_CATEGORY_MAPPING_QUERY = graphql(`
query clinicCategoryMappings($take: Int = 1, $skip: Int = 0, $where: ClinicCategoryMappingFilterInput!, $order: [ClinicCategoryMappingSortInput!]) {
  clinicCategoryMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...ClinicCategoryMapping
    }
    totalCount
  }
}`)

export const BRICK_MAPPING_QUERY = graphql(`
query BrickMappings($take: Int = 1, $skip: Int = 0, $where: BrickMappingFilterInput!, $order: [BrickMappingSortInput!]) {
  brickMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...BrickMapping
    }
    totalCount
  }
}`)

export const PERSON_STATUS_MAPPING_QUERY = graphql(`
query PersonStatusMappings($take: Int = 1, $skip: Int = 0, $where: PersonStatusMappingFilterInput!, $order: [PersonStatusMappingSortInput!]) {
  personStatusMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...PersonStatusMapping
    }
    totalCount
  }
}`)

export const ORGANIZATION_STATUS_MAPPING_QUERY = graphql(`
query OrganizationStatusMappings($take: Int = 1, $skip: Int = 0, $where: OrganizationStatusMappingFilterInput!, $order: [OrganizationStatusMappingSortInput!]) {
  organizationStatusMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...OrganizationStatusMapping
    }
    totalCount
  }
}`)

export const PERSON_MAPPING_QUERY = graphql(`
query PersonMappings($take: Int = 1, $skip: Int = 0, $where: PersonMappingFilterInput!, $order: [PersonMappingSortInput!]) {
  personMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...PersonMapping
    }
    totalCount
  }
}`)


export const ORGANIZATION_MAPPING_QUERY = graphql(`
query OrganizationMappings($take: Int = 1, $skip: Int = 0, $where: OrganizationMappingFilterInput!, $order: [OrganizationMappingSortInput!]) {
  organizationMappings(
    where: $where
    take: $take
    skip: $skip, 
	order: $order
  ) {
    items {
      ...OrganizationMapping
    }
    totalCount
  }
}`)
