/* eslint-disable */
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any; }
};

export type AbstractDictionary = {
  __typename?: 'AbstractDictionary';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  nameEng: Scalars['String']['output'];
  nameRus: Scalars['String']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type Brick = {
  __typename?: 'Brick';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  nameEng: Scalars['String']['output'];
  nameRus: Scalars['String']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type BrickFilterInput = {
  and?: InputMaybe<Array<BrickFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  nameEng?: InputMaybe<StringOperationFilterInput>;
  nameRus?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BrickFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type BrickMapping = {
  __typename?: 'BrickMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clietnValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entity?: Maybe<Brick>;
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  names?: Maybe<AbstractDictionary>;
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type BrickMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<BrickMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clietnValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<BrickFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<BrickMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type BrickMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clietnValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<BrickSortInput>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type BrickMappingsCollectionSegment = {
  __typename?: 'BrickMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<BrickMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type BrickSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  nameEng?: InputMaybe<SortEnumType>;
  nameRus?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type BricksCollectionSegment = {
  __typename?: 'BricksCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Brick>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
};

export type Client = {
  __typename?: 'Client';
  correlationId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  createdByUser?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  secret?: Maybe<Scalars['String']['output']>;
  state: OcmObjectState;
  ticks: Scalars['Long']['output'];
};

export type ClientFilterInput = {
  and?: InputMaybe<Array<ClientFilterInput>>;
  correlationId?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<StringOperationFilterInput>;
  createdByUser?: InputMaybe<UserFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClientFilterInput>>;
  orgName?: InputMaybe<StringOperationFilterInput>;
  parentId?: InputMaybe<StringOperationFilterInput>;
  secret?: InputMaybe<StringOperationFilterInput>;
  state?: InputMaybe<OcmObjectStateOperationFilterInput>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type ClientSortInput = {
  correlationId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  createdByUser?: InputMaybe<UserSortInput>;
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  orgName?: InputMaybe<SortEnumType>;
  parentId?: InputMaybe<SortEnumType>;
  secret?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ClientsCollectionSegment = {
  __typename?: 'ClientsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Client>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type ClinicCategoriesCollectionSegment = {
  __typename?: 'ClinicCategoriesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ClinicCategory>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
};

export type ClinicCategory = {
  __typename?: 'ClinicCategory';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  nameEng: Scalars['String']['output'];
  nameRus: Scalars['String']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type ClinicCategoryFilterInput = {
  and?: InputMaybe<Array<ClinicCategoryFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  nameEng?: InputMaybe<StringOperationFilterInput>;
  nameRus?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClinicCategoryFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type ClinicCategoryMapping = {
  __typename?: 'ClinicCategoryMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clietnValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entity?: Maybe<ClinicCategory>;
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  names?: Maybe<AbstractDictionary>;
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type ClinicCategoryMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ClinicCategoryMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clietnValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<ClinicCategoryFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ClinicCategoryMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type ClinicCategoryMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clietnValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<ClinicCategorySortInput>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ClinicCategoryMappingsCollectionSegment = {
  __typename?: 'ClinicCategoryMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ClinicCategoryMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ClinicCategorySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  nameEng?: InputMaybe<SortEnumType>;
  nameRus?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

/** A segment of a collection. */
export type LocalitiesCollectionSegment = {
  __typename?: 'LocalitiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Locality>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
};

export type Locality = {
  __typename?: 'Locality';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  nameEng: Scalars['String']['output'];
  nameRus: Scalars['String']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type LocalityFilterInput = {
  and?: InputMaybe<Array<LocalityFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  nameEng?: InputMaybe<StringOperationFilterInput>;
  nameRus?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocalityFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type LocalityMapping = {
  __typename?: 'LocalityMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clietnValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entity?: Maybe<Locality>;
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  names?: Maybe<AbstractDictionary>;
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type LocalityMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<LocalityMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clietnValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<LocalityFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<LocalityMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type LocalityMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clietnValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<LocalitySortInput>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type LocalityMappingsCollectionSegment = {
  __typename?: 'LocalityMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<LocalityMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type LocalitySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  nameEng?: InputMaybe<SortEnumType>;
  nameRus?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  removeBrick?: Maybe<Brick>;
  removeBrickMapping?: Maybe<BrickMapping>;
  removeClient?: Maybe<Client>;
  removeClinicCategory?: Maybe<ClinicCategory>;
  removeClinicCategoryMapping?: Maybe<ClinicCategoryMapping>;
  removeLocality?: Maybe<Locality>;
  removeLocalityMapping?: Maybe<LocalityMapping>;
  removeOrganizationMapping?: Maybe<OrganizationMapping>;
  removeOrganizationStatusMapping?: Maybe<OrganizationStatusMapping>;
  removePersonMapping?: Maybe<PersonMapping>;
  removePersonStatusMapping?: Maybe<PersonStatusMapping>;
  removePost?: Maybe<Post>;
  removePostMapping?: Maybe<PostMapping>;
  removeRecordTypeMapping?: Maybe<RecordTypeMapping>;
  removeSpeciality?: Maybe<Speciality>;
  removeSpecialityMapping?: Maybe<SpecialityMapping>;
  removeTypeLocality?: Maybe<TypeLocality>;
  removeTypeLocalityMapping?: Maybe<TypeLocalityMapping>;
  removeTypeOrg?: Maybe<TypeOrg>;
  removeTypeOrgMapping?: Maybe<TypeOrgMapping>;
  setBrick: Brick;
  setBrickMapping: BrickMapping;
  setClient: Client;
  setClinicCategory: ClinicCategory;
  setClinicCategoryMapping: ClinicCategoryMapping;
  setLocality: Locality;
  setLocalityMapping: LocalityMapping;
  setOrganizationMapping: OrganizationMapping;
  setOrganizationStatusMapping: OrganizationStatusMapping;
  setPersonMapping: PersonMapping;
  setPersonStatusMapping: PersonStatusMapping;
  setPost: Post;
  setPostMapping: PostMapping;
  setRecordTypeMapping: RecordTypeMapping;
  setSpeciality: Speciality;
  setSpecialityMapping: SpecialityMapping;
  setTypeLocality: TypeLocality;
  setTypeLocalityMapping: TypeLocalityMapping;
  setTypeOrg: TypeOrg;
  setTypeOrgMapping: TypeOrgMapping;
};


export type MutationRemoveBrickArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveBrickMappingArgs = {
  input: RemoveMappingInput;
};


export type MutationRemoveClientArgs = {
  input: RemoveClientInput;
};


export type MutationRemoveClinicCategoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveClinicCategoryMappingArgs = {
  input: RemoveMappingInput;
};


export type MutationRemoveLocalityArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveLocalityMappingArgs = {
  input: RemoveMappingInput;
};


export type MutationRemoveOrganizationMappingArgs = {
  input: RemoveMappingInput;
};


export type MutationRemoveOrganizationStatusMappingArgs = {
  input: RemoveMappingInput;
};


export type MutationRemovePersonMappingArgs = {
  input: RemoveMappingInput;
};


export type MutationRemovePersonStatusMappingArgs = {
  input: RemovePersonStatusMappingInput;
};


export type MutationRemovePostArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemovePostMappingArgs = {
  input: RemoveMappingInput;
};


export type MutationRemoveRecordTypeMappingArgs = {
  input: RemoveMappingInput;
};


export type MutationRemoveSpecialityArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveSpecialityMappingArgs = {
  input: RemoveMappingInput;
};


export type MutationRemoveTypeLocalityArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveTypeLocalityMappingArgs = {
  input: RemoveMappingInput;
};


export type MutationRemoveTypeOrgArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveTypeOrgMappingArgs = {
  input: RemoveMappingInput;
};


export type MutationSetBrickArgs = {
  input: SetDictionaryInput;
};


export type MutationSetBrickMappingArgs = {
  input: SetMappingInput;
};


export type MutationSetClientArgs = {
  input: SetClientInput;
};


export type MutationSetClinicCategoryArgs = {
  input: SetJurisdictionBoundedDictionaryInput;
};


export type MutationSetClinicCategoryMappingArgs = {
  input: SetMappingInput;
};


export type MutationSetLocalityArgs = {
  input: SetJurisdictionBoundedDictionaryInput;
};


export type MutationSetLocalityMappingArgs = {
  input: SetMappingInput;
};


export type MutationSetOrganizationMappingArgs = {
  input: SetMappingInput;
};


export type MutationSetOrganizationStatusMappingArgs = {
  input: SetMappingInput;
};


export type MutationSetPersonMappingArgs = {
  input: SetMappingInput;
};


export type MutationSetPersonStatusMappingArgs = {
  input: SetPersonStatusMappingInput;
};


export type MutationSetPostArgs = {
  input: SetJurisdictionBoundedDictionaryInput;
};


export type MutationSetPostMappingArgs = {
  input: SetMappingInput;
};


export type MutationSetRecordTypeMappingArgs = {
  input: SetMappingInput;
};


export type MutationSetSpecialityArgs = {
  input: SetJurisdictionBoundedDictionaryInput;
};


export type MutationSetSpecialityMappingArgs = {
  input: SetMappingInput;
};


export type MutationSetTypeLocalityArgs = {
  input: SetJurisdictionBoundedDictionaryInput;
};


export type MutationSetTypeLocalityMappingArgs = {
  input: SetMappingInput;
};


export type MutationSetTypeOrgArgs = {
  input: SetJurisdictionBoundedDictionaryInput;
};


export type MutationSetTypeOrgMappingArgs = {
  input: SetMappingInput;
};

export enum OcmObjectState {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

export type OcmObjectStateOperationFilterInput = {
  eq?: InputMaybe<OcmObjectState>;
  in?: InputMaybe<Array<OcmObjectState>>;
  neq?: InputMaybe<OcmObjectState>;
  nin?: InputMaybe<Array<OcmObjectState>>;
};

export type OrganizationMapping = {
  __typename?: 'OrganizationMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clietnValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type OrganizationMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<OrganizationMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clietnValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<OrganizationMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type OrganizationMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clietnValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type OrganizationMappingsCollectionSegment = {
  __typename?: 'OrganizationMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<OrganizationMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrganizationStatus = {
  __typename?: 'OrganizationStatus';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  nameEng: Scalars['String']['output'];
  nameRus: Scalars['String']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type OrganizationStatusFilterInput = {
  and?: InputMaybe<Array<OrganizationStatusFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  nameEng?: InputMaybe<StringOperationFilterInput>;
  nameRus?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganizationStatusFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type OrganizationStatusMapping = {
  __typename?: 'OrganizationStatusMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clietnValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entity?: Maybe<OrganizationStatus>;
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  names?: Maybe<AbstractDictionary>;
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type OrganizationStatusMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<OrganizationStatusMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clietnValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<OrganizationStatusFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<OrganizationStatusMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type OrganizationStatusMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clietnValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<OrganizationStatusSortInput>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type OrganizationStatusMappingsCollectionSegment = {
  __typename?: 'OrganizationStatusMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<OrganizationStatusMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrganizationStatusSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  nameEng?: InputMaybe<SortEnumType>;
  nameRus?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

export type PersonMapping = {
  __typename?: 'PersonMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clietnValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type PersonMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<PersonMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clietnValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PersonMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type PersonMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clietnValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PersonMappingsCollectionSegment = {
  __typename?: 'PersonMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PersonMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PersonStatus = {
  __typename?: 'PersonStatus';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  nameEng: Scalars['String']['output'];
  nameRus: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type PersonStatusFilterInput = {
  and?: InputMaybe<Array<PersonStatusFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  nameEng?: InputMaybe<StringOperationFilterInput>;
  nameRus?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PersonStatusFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type PersonStatusMapping = {
  __typename?: 'PersonStatusMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clientValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  names?: Maybe<PersonStatus>;
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type PersonStatusMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<PersonStatusMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clientValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PersonStatusMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type PersonStatusMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clientValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PersonStatusMappingsCollectionSegment = {
  __typename?: 'PersonStatusMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PersonStatusMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PersonStatusSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  nameEng?: InputMaybe<SortEnumType>;
  nameRus?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PersonStatusesCollectionSegment = {
  __typename?: 'PersonStatusesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PersonStatus>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
};

export type Post = {
  __typename?: 'Post';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  nameEng: Scalars['String']['output'];
  nameRus: Scalars['String']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type PostFilterInput = {
  and?: InputMaybe<Array<PostFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  nameEng?: InputMaybe<StringOperationFilterInput>;
  nameRus?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PostFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type PostMapping = {
  __typename?: 'PostMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clietnValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entity?: Maybe<Post>;
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  names?: Maybe<AbstractDictionary>;
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type PostMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<PostMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clietnValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<PostFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PostMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type PostMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clietnValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<PostSortInput>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PostMappingsCollectionSegment = {
  __typename?: 'PostMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PostMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PostSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  nameEng?: InputMaybe<SortEnumType>;
  nameRus?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PostsCollectionSegment = {
  __typename?: 'PostsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Post>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
};

export type Query = {
  __typename?: 'Query';
  brickMappings?: Maybe<BrickMappingsCollectionSegment>;
  bricks?: Maybe<BricksCollectionSegment>;
  clientById?: Maybe<Client>;
  clients?: Maybe<ClientsCollectionSegment>;
  clinicCategories?: Maybe<ClinicCategoriesCollectionSegment>;
  clinicCategoryMappings?: Maybe<ClinicCategoryMappingsCollectionSegment>;
  localities?: Maybe<LocalitiesCollectionSegment>;
  localityMappings?: Maybe<LocalityMappingsCollectionSegment>;
  organizationMappings?: Maybe<OrganizationMappingsCollectionSegment>;
  organizationStatusMappings?: Maybe<OrganizationStatusMappingsCollectionSegment>;
  personMappings?: Maybe<PersonMappingsCollectionSegment>;
  personStatusMappings?: Maybe<PersonStatusMappingsCollectionSegment>;
  personStatuses?: Maybe<PersonStatusesCollectionSegment>;
  postMappings?: Maybe<PostMappingsCollectionSegment>;
  posts?: Maybe<PostsCollectionSegment>;
  recordTypeMappings?: Maybe<RecordTypeMappingsCollectionSegment>;
  specialities?: Maybe<SpecialitiesCollectionSegment>;
  specialityMappings?: Maybe<SpecialityMappingsCollectionSegment>;
  typeLocalities?: Maybe<TypeLocalitiesCollectionSegment>;
  typeLocalityMappings?: Maybe<TypeLocalityMappingsCollectionSegment>;
  typeOrgMappings?: Maybe<TypeOrgMappingsCollectionSegment>;
  typeOrgs?: Maybe<TypeOrgsCollectionSegment>;
};


export type QueryBrickMappingsArgs = {
  order?: InputMaybe<Array<BrickMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BrickMappingFilterInput>;
};


export type QueryBricksArgs = {
  order?: InputMaybe<Array<BrickSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BrickFilterInput>;
};


export type QueryClientByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClientsArgs = {
  order?: InputMaybe<Array<ClientSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientFilterInput>;
};


export type QueryClinicCategoriesArgs = {
  order?: InputMaybe<Array<ClinicCategorySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClinicCategoryFilterInput>;
};


export type QueryClinicCategoryMappingsArgs = {
  order?: InputMaybe<Array<ClinicCategoryMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClinicCategoryMappingFilterInput>;
};


export type QueryLocalitiesArgs = {
  order?: InputMaybe<Array<LocalitySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocalityFilterInput>;
};


export type QueryLocalityMappingsArgs = {
  order?: InputMaybe<Array<LocalityMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocalityMappingFilterInput>;
};


export type QueryOrganizationMappingsArgs = {
  order?: InputMaybe<Array<OrganizationMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationMappingFilterInput>;
};


export type QueryOrganizationStatusMappingsArgs = {
  order?: InputMaybe<Array<OrganizationStatusMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationStatusMappingFilterInput>;
};


export type QueryPersonMappingsArgs = {
  order?: InputMaybe<Array<PersonMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PersonMappingFilterInput>;
};


export type QueryPersonStatusMappingsArgs = {
  order?: InputMaybe<Array<PersonStatusMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PersonStatusMappingFilterInput>;
};


export type QueryPersonStatusesArgs = {
  order?: InputMaybe<Array<PersonStatusSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PersonStatusFilterInput>;
};


export type QueryPostMappingsArgs = {
  order?: InputMaybe<Array<PostMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostMappingFilterInput>;
};


export type QueryPostsArgs = {
  order?: InputMaybe<Array<PostSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostFilterInput>;
};


export type QueryRecordTypeMappingsArgs = {
  order?: InputMaybe<Array<RecordTypeMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RecordTypeMappingFilterInput>;
};


export type QuerySpecialitiesArgs = {
  order?: InputMaybe<Array<SpecialitySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpecialityFilterInput>;
};


export type QuerySpecialityMappingsArgs = {
  order?: InputMaybe<Array<SpecialityMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpecialityMappingFilterInput>;
};


export type QueryTypeLocalitiesArgs = {
  order?: InputMaybe<Array<TypeLocalitySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TypeLocalityFilterInput>;
};


export type QueryTypeLocalityMappingsArgs = {
  order?: InputMaybe<Array<TypeLocalityMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TypeLocalityMappingFilterInput>;
};


export type QueryTypeOrgMappingsArgs = {
  order?: InputMaybe<Array<TypeOrgMappingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TypeOrgMappingFilterInput>;
};


export type QueryTypeOrgsArgs = {
  order?: InputMaybe<Array<TypeOrgSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TypeOrgFilterInput>;
};

export type RecordTypeMapping = {
  __typename?: 'RecordTypeMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clietnValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entity?: Maybe<TypeOrg>;
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  names?: Maybe<AbstractDictionary>;
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type RecordTypeMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<RecordTypeMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clietnValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<TypeOrgFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<RecordTypeMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type RecordTypeMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clietnValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<TypeOrgSortInput>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type RecordTypeMappingsCollectionSegment = {
  __typename?: 'RecordTypeMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<RecordTypeMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type RemoveClientInput = {
  clientId: Scalars['String']['input'];
};

export type RemoveMappingInput = {
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
};

export type RemovePersonStatusMappingInput = {
  clientId: Scalars['String']['input'];
  entityId: Scalars['Int']['input'];
  jurisdictionId: Scalars['String']['input'];
};

export type SetClientInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
  secret?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type SetDictionaryInput = {
  id: Scalars['String']['input'];
  nameEng: Scalars['String']['input'];
  nameRus: Scalars['String']['input'];
};

export type SetJurisdictionBoundedDictionaryInput = {
  id: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
  nameEng: Scalars['String']['input'];
  nameRus: Scalars['String']['input'];
};

export type SetMappingInput = {
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['String']['input'];
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
};

export type SetPersonStatusMappingInput = {
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['String']['input'];
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['Int']['input'];
  jurisdictionId: Scalars['String']['input'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** A segment of a collection. */
export type SpecialitiesCollectionSegment = {
  __typename?: 'SpecialitiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Speciality>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
};

export type Speciality = {
  __typename?: 'Speciality';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  nameEng: Scalars['String']['output'];
  nameRus: Scalars['String']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type SpecialityFilterInput = {
  and?: InputMaybe<Array<SpecialityFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  nameEng?: InputMaybe<StringOperationFilterInput>;
  nameRus?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SpecialityFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type SpecialityMapping = {
  __typename?: 'SpecialityMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clietnValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entity?: Maybe<Speciality>;
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  names?: Maybe<AbstractDictionary>;
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type SpecialityMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<SpecialityMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clietnValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<SpecialityFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<SpecialityMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type SpecialityMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clietnValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<SpecialitySortInput>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type SpecialityMappingsCollectionSegment = {
  __typename?: 'SpecialityMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<SpecialityMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type SpecialitySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  nameEng?: InputMaybe<SortEnumType>;
  nameRus?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

/** A segment of a collection. */
export type TypeLocalitiesCollectionSegment = {
  __typename?: 'TypeLocalitiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<TypeLocality>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
};

export type TypeLocality = {
  __typename?: 'TypeLocality';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  nameEng: Scalars['String']['output'];
  nameRus: Scalars['String']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type TypeLocalityFilterInput = {
  and?: InputMaybe<Array<TypeLocalityFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  nameEng?: InputMaybe<StringOperationFilterInput>;
  nameRus?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeLocalityFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type TypeLocalityMapping = {
  __typename?: 'TypeLocalityMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clietnValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entity?: Maybe<TypeLocality>;
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  names?: Maybe<AbstractDictionary>;
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type TypeLocalityMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<TypeLocalityMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clietnValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<TypeLocalityFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TypeLocalityMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type TypeLocalityMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clietnValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<TypeLocalitySortInput>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type TypeLocalityMappingsCollectionSegment = {
  __typename?: 'TypeLocalityMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<TypeLocalityMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type TypeLocalitySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  nameEng?: InputMaybe<SortEnumType>;
  nameRus?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

export type TypeOrg = {
  __typename?: 'TypeOrg';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  nameEng: Scalars['String']['output'];
  nameRus: Scalars['String']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type TypeOrgFilterInput = {
  and?: InputMaybe<Array<TypeOrgFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  nameEng?: InputMaybe<StringOperationFilterInput>;
  nameRus?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeOrgFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type TypeOrgMapping = {
  __typename?: 'TypeOrgMapping';
  alternativeClientValue?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  clietnValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entity?: Maybe<TypeOrg>;
  entityDescription?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jurisdictionId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  names?: Maybe<AbstractDictionary>;
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  ticks: Scalars['Long']['output'];
};

export type TypeOrgMappingFilterInput = {
  alternativeClientValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<TypeOrgMappingFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  clietnValue?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<TypeOrgFilterInput>;
  entityDescription?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jurisdictionId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TypeOrgMappingFilterInput>>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type TypeOrgMappingSortInput = {
  alternativeClientValue?: InputMaybe<SortEnumType>;
  clientId?: InputMaybe<SortEnumType>;
  clietnValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<TypeOrgSortInput>;
  entityDescription?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type TypeOrgMappingsCollectionSegment = {
  __typename?: 'TypeOrgMappingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<TypeOrgMapping>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type TypeOrgSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jurisdictionId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  nameEng?: InputMaybe<SortEnumType>;
  nameRus?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type TypeOrgsCollectionSegment = {
  __typename?: 'TypeOrgsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<TypeOrg>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
};

export type User = {
  __typename?: 'User';
  correlationId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parentId: Scalars['String']['output'];
  schemaId: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  state: OcmObjectState;
  ticks: Scalars['Long']['output'];
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  correlationId?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  parentId?: InputMaybe<StringOperationFilterInput>;
  state?: InputMaybe<OcmObjectStateOperationFilterInput>;
  ticks?: InputMaybe<LongOperationFilterInput>;
};

export type UserSortInput = {
  correlationId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentId?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  ticks?: InputMaybe<SortEnumType>;
};

export type ClientFragment = { __typename?: 'Client', id: string, name: string, orgName: string, createdAt: any, secret?: string | null, description?: string | null, modifiedAt: any, createdByUser?: { __typename?: 'User', name?: string | null, id: string } | null };

export type TypeOrgMappingFragment = { __typename?: 'TypeOrgMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null };

export type TypeLocalityMappingFragment = { __typename?: 'TypeLocalityMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null };

export type SpecialityMappingFragment = { __typename?: 'SpecialityMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null };

export type RecordTypeMappingFragment = { __typename?: 'RecordTypeMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null };

export type PostMappingFragment = { __typename?: 'PostMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null };

export type LocalityMappingFragment = { __typename?: 'LocalityMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null };

export type ClinicCategoryMappingFragment = { __typename?: 'ClinicCategoryMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null };

export type BrickMappingFragment = { __typename?: 'BrickMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null };

export type OrganizationStatusMappingFragment = { __typename?: 'OrganizationStatusMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null };

export type PersonStatusMappingFragment = { __typename?: 'PersonStatusMapping', id: string, entityId: number, createdAt: any, clientId: string, clientValue: string, alternativeClientValue?: string | null, entityDescription?: string | null, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'PersonStatus', nameRus: string } | null };

export type PersonMappingFragment = { __typename?: 'PersonMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null };

export type OrganizationMappingFragment = { __typename?: 'OrganizationMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null };

export type SetClientMutationVariables = Exact<{
  clientId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
  secret?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
}>;


export type SetClientMutation = { __typename?: 'Mutation', setClient: { __typename?: 'Client', id: string } };

export type RemoveBrickMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemoveBrickMappingMutation = { __typename?: 'Mutation', removeBrickMapping?: { __typename?: 'BrickMapping', id: string } | null };

export type RemoveClinicCategoryMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemoveClinicCategoryMappingMutation = { __typename?: 'Mutation', removeClinicCategoryMapping?: { __typename?: 'ClinicCategoryMapping', id: string } | null };

export type RemoveLocalityMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemoveLocalityMappingMutation = { __typename?: 'Mutation', removeLocalityMapping?: { __typename?: 'LocalityMapping', id: string } | null };

export type RemoveOrganizationStatusMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemoveOrganizationStatusMappingMutation = { __typename?: 'Mutation', removeOrganizationStatusMapping?: { __typename?: 'OrganizationStatusMapping', id: string } | null };

export type SetBrickMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetBrickMappingMutation = { __typename?: 'Mutation', setBrickMapping: { __typename?: 'BrickMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null } };

export type SetClinicCategoryMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetClinicCategoryMappingMutation = { __typename?: 'Mutation', setClinicCategoryMapping: { __typename?: 'ClinicCategoryMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null } };

export type SetLocalityMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetLocalityMappingMutation = { __typename?: 'Mutation', setLocalityMapping: { __typename?: 'LocalityMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null } };

export type SetOrganizationStatusMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetOrganizationStatusMappingMutation = { __typename?: 'Mutation', setOrganizationStatusMapping: { __typename?: 'OrganizationStatusMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null } };

export type RemovePostMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemovePostMappingMutation = { __typename?: 'Mutation', removePostMapping?: { __typename?: 'PostMapping', id: string } | null };

export type SetPostMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetPostMappingMutation = { __typename?: 'Mutation', setPostMapping: { __typename?: 'PostMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null } };

export type RemoveRecordTypeMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemoveRecordTypeMappingMutation = { __typename?: 'Mutation', removeRecordTypeMapping?: { __typename?: 'RecordTypeMapping', id: string } | null };

export type SetRecordTypeMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetRecordTypeMappingMutation = { __typename?: 'Mutation', setRecordTypeMapping: { __typename?: 'RecordTypeMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null } };

export type RemoveSpecialityMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemoveSpecialityMappingMutation = { __typename?: 'Mutation', removeSpecialityMapping?: { __typename?: 'SpecialityMapping', id: string } | null };

export type SetSpecialityMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetSpecialityMappingMutation = { __typename?: 'Mutation', setSpecialityMapping: { __typename?: 'SpecialityMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null } };

export type RemoveTypeLocalityMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemoveTypeLocalityMappingMutation = { __typename?: 'Mutation', removeTypeLocalityMapping?: { __typename?: 'TypeLocalityMapping', id: string } | null };

export type SetTypeLocalityMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetTypeLocalityMappingMutation = { __typename?: 'Mutation', setTypeLocalityMapping: { __typename?: 'TypeLocalityMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null } };

export type RemoveTypeOrgMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemoveTypeOrgMappingMutation = { __typename?: 'Mutation', removeTypeOrgMapping?: { __typename?: 'TypeOrgMapping', id: string } | null };

export type SetTypeOrgMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetTypeOrgMappingMutation = { __typename?: 'Mutation', setTypeOrgMapping: { __typename?: 'TypeOrgMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null } };

export type RemovePersonStatusMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['Int']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemovePersonStatusMappingMutation = { __typename?: 'Mutation', removePersonStatusMapping?: { __typename?: 'PersonStatusMapping', id: string } | null };

export type SetPersonStatusMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['Int']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetPersonStatusMappingMutation = { __typename?: 'Mutation', setPersonStatusMapping: { __typename?: 'PersonStatusMapping', id: string, entityId: number, createdAt: any, clientId: string, clientValue: string, alternativeClientValue?: string | null, entityDescription?: string | null, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'PersonStatus', nameRus: string } | null } };

export type SetPersonMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetPersonMappingMutation = { __typename?: 'Mutation', setPersonMapping: { __typename?: 'PersonMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null } };

export type SetOrganizationMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  alternativeClientValue?: InputMaybe<Scalars['String']['input']>;
  clientValue: Scalars['String']['input'];
  entityDescription?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId: Scalars['String']['input'];
}>;


export type SetOrganizationMappingMutation = { __typename?: 'Mutation', setOrganizationMapping: { __typename?: 'OrganizationMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null } };

export type RemovePersonMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemovePersonMappingMutation = { __typename?: 'Mutation', removePersonMapping?: { __typename?: 'PersonMapping', id: string } | null };

export type RemoveOrganizationMappingMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
}>;


export type RemoveOrganizationMappingMutation = { __typename?: 'Mutation', removeOrganizationMapping?: { __typename?: 'OrganizationMapping', id: string } | null };

export type ClientsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ClientSortInput> | ClientSortInput>;
}>;


export type ClientsQuery = { __typename?: 'Query', clients?: { __typename?: 'ClientsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Client', id: string, name: string, orgName: string, createdAt: any, secret?: string | null, description?: string | null, modifiedAt: any, createdByUser?: { __typename?: 'User', name?: string | null, id: string } | null }> | null } | null };

export type TypeOrgMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: TypeOrgMappingFilterInput;
  order?: InputMaybe<Array<TypeOrgMappingSortInput> | TypeOrgMappingSortInput>;
}>;


export type TypeOrgMappingsQuery = { __typename?: 'Query', typeOrgMappings?: { __typename?: 'TypeOrgMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'TypeOrgMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null }> | null } | null };

export type TypeLocalityMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: TypeLocalityMappingFilterInput;
  order?: InputMaybe<Array<TypeLocalityMappingSortInput> | TypeLocalityMappingSortInput>;
}>;


export type TypeLocalityMappingsQuery = { __typename?: 'Query', typeLocalityMappings?: { __typename?: 'TypeLocalityMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'TypeLocalityMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null }> | null } | null };

export type SpecialityMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: SpecialityMappingFilterInput;
  order?: InputMaybe<Array<SpecialityMappingSortInput> | SpecialityMappingSortInput>;
}>;


export type SpecialityMappingsQuery = { __typename?: 'Query', specialityMappings?: { __typename?: 'SpecialityMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'SpecialityMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null }> | null } | null };

export type RecordTypeMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: RecordTypeMappingFilterInput;
  order?: InputMaybe<Array<RecordTypeMappingSortInput> | RecordTypeMappingSortInput>;
}>;


export type RecordTypeMappingsQuery = { __typename?: 'Query', recordTypeMappings?: { __typename?: 'RecordTypeMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'RecordTypeMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null }> | null } | null };

export type PostMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: PostMappingFilterInput;
  order?: InputMaybe<Array<PostMappingSortInput> | PostMappingSortInput>;
}>;


export type PostMappingsQuery = { __typename?: 'Query', postMappings?: { __typename?: 'PostMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'PostMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null }> | null } | null };

export type LocalityMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: LocalityMappingFilterInput;
  order?: InputMaybe<Array<LocalityMappingSortInput> | LocalityMappingSortInput>;
}>;


export type LocalityMappingsQuery = { __typename?: 'Query', localityMappings?: { __typename?: 'LocalityMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'LocalityMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null }> | null } | null };

export type ClinicCategoryMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: ClinicCategoryMappingFilterInput;
  order?: InputMaybe<Array<ClinicCategoryMappingSortInput> | ClinicCategoryMappingSortInput>;
}>;


export type ClinicCategoryMappingsQuery = { __typename?: 'Query', clinicCategoryMappings?: { __typename?: 'ClinicCategoryMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'ClinicCategoryMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null }> | null } | null };

export type BrickMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: BrickMappingFilterInput;
  order?: InputMaybe<Array<BrickMappingSortInput> | BrickMappingSortInput>;
}>;


export type BrickMappingsQuery = { __typename?: 'Query', brickMappings?: { __typename?: 'BrickMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'BrickMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null }> | null } | null };

export type PersonStatusMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: PersonStatusMappingFilterInput;
  order?: InputMaybe<Array<PersonStatusMappingSortInput> | PersonStatusMappingSortInput>;
}>;


export type PersonStatusMappingsQuery = { __typename?: 'Query', personStatusMappings?: { __typename?: 'PersonStatusMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'PersonStatusMapping', id: string, entityId: number, createdAt: any, clientId: string, clientValue: string, alternativeClientValue?: string | null, entityDescription?: string | null, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'PersonStatus', nameRus: string } | null }> | null } | null };

export type OrganizationStatusMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: OrganizationStatusMappingFilterInput;
  order?: InputMaybe<Array<OrganizationStatusMappingSortInput> | OrganizationStatusMappingSortInput>;
}>;


export type OrganizationStatusMappingsQuery = { __typename?: 'Query', organizationStatusMappings?: { __typename?: 'OrganizationStatusMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'OrganizationStatusMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null, names?: { __typename?: 'AbstractDictionary', nameRus: string } | null }> | null } | null };

export type PersonMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: PersonMappingFilterInput;
  order?: InputMaybe<Array<PersonMappingSortInput> | PersonMappingSortInput>;
}>;


export type PersonMappingsQuery = { __typename?: 'Query', personMappings?: { __typename?: 'PersonMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'PersonMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null }> | null } | null };

export type OrganizationMappingsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: OrganizationMappingFilterInput;
  order?: InputMaybe<Array<OrganizationMappingSortInput> | OrganizationMappingSortInput>;
}>;


export type OrganizationMappingsQuery = { __typename?: 'Query', organizationMappings?: { __typename?: 'OrganizationMappingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'OrganizationMapping', id: string, entityId: string, createdAt: any, clientId: string, alternativeClientValue?: string | null, entityDescription?: string | null, clientValue: string, client?: { __typename?: 'Client', name: string, id: string } | null }> | null } | null };

export const ClientFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "Client"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "Client"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "name"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "orgName"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "createdAt"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "secret"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "createdByUser"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "description"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "modifiedAt"}
      }]
    }
  }]
} as unknown as DocumentNode<ClientFragment, unknown>;
export const TypeOrgMappingFragmentDoc = {
  "kind": "Document", "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "TypeOrgMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "TypeOrgMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<TypeOrgMappingFragment, unknown>;
export const TypeLocalityMappingFragmentDoc = {
  "kind": "Document", "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "TypeLocalityMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "TypeLocalityMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<TypeLocalityMappingFragment, unknown>;
export const SpecialityMappingFragmentDoc = {
  "kind": "Document", "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "SpecialityMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "SpecialityMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<SpecialityMappingFragment, unknown>;
export const RecordTypeMappingFragmentDoc = {
  "kind": "Document", "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "RecordTypeMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "RecordTypeMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RecordTypeMappingFragment, unknown>;
export const PostMappingFragmentDoc = {
  "kind": "Document", "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "PostMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "PostMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<PostMappingFragment, unknown>;
export const LocalityMappingFragmentDoc = {
  "kind": "Document", "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "LocalityMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "LocalityMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<LocalityMappingFragment, unknown>;
export const ClinicCategoryMappingFragmentDoc = {
  "kind": "Document", "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "ClinicCategoryMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "ClinicCategoryMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<ClinicCategoryMappingFragment, unknown>;
export const BrickMappingFragmentDoc = {
  "kind": "Document", "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "BrickMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "BrickMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<BrickMappingFragment, unknown>;
export const OrganizationStatusMappingFragmentDoc = {
  "kind": "Document", "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "OrganizationStatusMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "OrganizationStatusMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<OrganizationStatusMappingFragment, unknown>;
export const PersonStatusMappingFragmentDoc = {
  "kind": "Document", "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "PersonStatusMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "PersonStatusMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "clientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "alternativeClientValue"}
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<PersonStatusMappingFragment, unknown>;
export const PersonMappingFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "PersonMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "PersonMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<PersonMappingFragment, unknown>;
export const OrganizationMappingFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "OrganizationMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "OrganizationMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<OrganizationMappingFragment, unknown>;
export const SetClientDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetClient"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "description"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "name"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "orgName"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "secret"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "userId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setClient"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "description"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "description"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "name"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "name"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "orgName"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "orgName"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "secret"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "secret"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "userId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "userId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<SetClientMutation, SetClientMutationVariables>;
export const RemoveBrickMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemoveBrickMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removeBrickMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemoveBrickMappingMutation, RemoveBrickMappingMutationVariables>;
export const RemoveClinicCategoryMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemoveClinicCategoryMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removeClinicCategoryMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemoveClinicCategoryMappingMutation, RemoveClinicCategoryMappingMutationVariables>;
export const RemoveLocalityMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemoveLocalityMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removeLocalityMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemoveLocalityMappingMutation, RemoveLocalityMappingMutationVariables>;
export const RemoveOrganizationStatusMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemoveOrganizationStatusMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removeOrganizationStatusMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemoveOrganizationStatusMappingMutation, RemoveOrganizationStatusMappingMutationVariables>;
export const SetBrickMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetBrickMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setBrickMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "BrickMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "BrickMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "BrickMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<SetBrickMappingMutation, SetBrickMappingMutationVariables>;
export const SetClinicCategoryMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetClinicCategoryMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setClinicCategoryMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "ClinicCategoryMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "ClinicCategoryMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "ClinicCategoryMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<SetClinicCategoryMappingMutation, SetClinicCategoryMappingMutationVariables>;
export const SetLocalityMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetLocalityMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setLocalityMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "LocalityMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "LocalityMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "LocalityMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<SetLocalityMappingMutation, SetLocalityMappingMutationVariables>;
export const SetOrganizationStatusMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetOrganizationStatusMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setOrganizationStatusMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "OrganizationStatusMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "OrganizationStatusMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "OrganizationStatusMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<SetOrganizationStatusMappingMutation, SetOrganizationStatusMappingMutationVariables>;
export const RemovePostMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemovePostMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removePostMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemovePostMappingMutation, RemovePostMappingMutationVariables>;
export const SetPostMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetPostMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setPostMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "PostMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "PostMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "PostMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<SetPostMappingMutation, SetPostMappingMutationVariables>;
export const RemoveRecordTypeMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemoveRecordTypeMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removeRecordTypeMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemoveRecordTypeMappingMutation, RemoveRecordTypeMappingMutationVariables>;
export const SetRecordTypeMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetRecordTypeMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setRecordTypeMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "RecordTypeMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "RecordTypeMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "RecordTypeMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<SetRecordTypeMappingMutation, SetRecordTypeMappingMutationVariables>;
export const RemoveSpecialityMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemoveSpecialityMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removeSpecialityMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemoveSpecialityMappingMutation, RemoveSpecialityMappingMutationVariables>;
export const SetSpecialityMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetSpecialityMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setSpecialityMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "SpecialityMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "SpecialityMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "SpecialityMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<SetSpecialityMappingMutation, SetSpecialityMappingMutationVariables>;
export const RemoveTypeLocalityMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemoveTypeLocalityMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removeTypeLocalityMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemoveTypeLocalityMappingMutation, RemoveTypeLocalityMappingMutationVariables>;
export const SetTypeLocalityMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetTypeLocalityMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setTypeLocalityMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "TypeLocalityMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "TypeLocalityMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "TypeLocalityMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<SetTypeLocalityMappingMutation, SetTypeLocalityMappingMutationVariables>;
export const RemoveTypeOrgMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemoveTypeOrgMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removeTypeOrgMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemoveTypeOrgMappingMutation, RemoveTypeOrgMappingMutationVariables>;
export const SetTypeOrgMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetTypeOrgMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setTypeOrgMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "TypeOrgMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "TypeOrgMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "TypeOrgMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<SetTypeOrgMappingMutation, SetTypeOrgMappingMutationVariables>;
export const RemovePersonStatusMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemovePersonStatusMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removePersonStatusMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemovePersonStatusMappingMutation, RemovePersonStatusMappingMutationVariables>;
export const SetPersonStatusMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetPersonStatusMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setPersonStatusMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "PersonStatusMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "PersonStatusMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "PersonStatusMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "clientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "alternativeClientValue"}
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<SetPersonStatusMappingMutation, SetPersonStatusMappingMutationVariables>;
export const SetPersonMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetPersonMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setPersonMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "PersonMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "PersonMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "PersonMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<SetPersonMappingMutation, SetPersonMappingMutationVariables>;
export const SetOrganizationMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "SetOrganizationMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "NullValue"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "setOrganizationMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "alternativeClientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "alternativeClientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientValue"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientValue"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityDescription"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityDescription"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "OrganizationMapping"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "OrganizationMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "OrganizationMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<SetOrganizationMappingMutation, SetOrganizationMappingMutationVariables>;
export const RemovePersonMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemovePersonMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removePersonMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemovePersonMappingMutation, RemovePersonMappingMutationVariables>;
export const RemoveOrganizationMappingDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {"kind": "Name", "value": "RemoveOrganizationMapping"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}},
      "type": {"kind": "NonNullType", "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}}}
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "removeOrganizationMapping"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "input"},
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "clientId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "clientId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "entityId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "entityId"}}
            }, {
              "kind": "ObjectField",
              "name": {"kind": "Name", "value": "jurisdictionId"},
              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "jurisdictionId"}}
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RemoveOrganizationMappingMutation, RemoveOrganizationMappingMutationVariables>;
export const ClientsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "CLIENTS"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "search"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "StringValue", "value": "", "block": false}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "startDate"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "DateTime"}},
      "defaultValue": {"kind": "StringValue", "value": "01-01-2000", "block": false}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "endDate"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "DateTime"}},
      "defaultValue": {"kind": "StringValue", "value": "01-01-3000", "block": false}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "id"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "String"}},
      "defaultValue": {"kind": "StringValue", "value": "", "block": false}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "sort"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "ClientSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet", "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "clients"},
        "arguments": [{
          "kind": "Argument", "name": {"kind": "Name", "value": "where"}, "value": {
            "kind": "ObjectValue", "fields": [{
              "kind": "ObjectField", "name": {"kind": "Name", "value": "and"}, "value": {
                "kind": "ListValue", "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {"kind": "Name", "value": "name"},
                    "value": {
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {"kind": "Name", "value": "contains"},
                        "value": {"kind": "Variable", "name": {"kind": "Name", "value": "search"}}
                      }]
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {"kind": "Name", "value": "and"},
                    "value": {
                      "kind": "ListValue",
                      "values": [{
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {"kind": "Name", "value": "createdAt"},
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {"kind": "Name", "value": "gte"},
                              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "startDate"}}
                            }]
                          }
                        }]
                      }, {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {"kind": "Name", "value": "createdAt"},
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {"kind": "Name", "value": "lte"},
                              "value": {"kind": "Variable", "name": {"kind": "Name", "value": "endDate"}}
                            }]
                          }
                        }]
                      }]
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {"kind": "Name", "value": "id"},
                    "value": {
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {"kind": "Name", "value": "contains"},
                        "value": {"kind": "Variable", "name": {"kind": "Name", "value": "id"}}
                      }]
                    }
                  }]
                }]
              }
            }]
          }
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "sort"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
                "kind": "Field",
                "name": {"kind": "Name", "value": "name"}
              }, {"kind": "Field", "name": {"kind": "Name", "value": "orgName"}}, {
                "kind": "Field",
                "name": {"kind": "Name", "value": "createdAt"}
              }, {"kind": "Field", "name": {"kind": "Name", "value": "secret"}}, {
                "kind": "Field",
                "name": {"kind": "Name", "value": "createdByUser"},
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
                    "kind": "Field",
                    "name": {"kind": "Name", "value": "id"}
                  }]
                }
              }, {"kind": "Field", "name": {"kind": "Name", "value": "description"}}, {
                "kind": "Field",
                "name": {"kind": "Name", "value": "modifiedAt"}
              }]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<ClientsQuery, ClientsQueryVariables>;
export const TypeOrgMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "TypeOrgMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "TypeOrgMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "TypeOrgMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "typeOrgMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "TypeOrgMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "TypeOrgMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "TypeOrgMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<TypeOrgMappingsQuery, TypeOrgMappingsQueryVariables>;
export const TypeLocalityMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "TypeLocalityMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "TypeLocalityMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "TypeLocalityMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "typeLocalityMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "TypeLocalityMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "TypeLocalityMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "TypeLocalityMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<TypeLocalityMappingsQuery, TypeLocalityMappingsQueryVariables>;
export const SpecialityMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "SpecialityMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "SpecialityMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "SpecialityMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "specialityMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "SpecialityMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "SpecialityMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "SpecialityMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<SpecialityMappingsQuery, SpecialityMappingsQueryVariables>;
export const RecordTypeMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "recordTypeMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "RecordTypeMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "RecordTypeMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "recordTypeMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "RecordTypeMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "RecordTypeMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "RecordTypeMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<RecordTypeMappingsQuery, RecordTypeMappingsQueryVariables>;
export const PostMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "postMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "PostMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "PostMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "postMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "PostMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "PostMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "PostMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<PostMappingsQuery, PostMappingsQueryVariables>;
export const LocalityMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "localityMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "LocalityMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "LocalityMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "localityMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "LocalityMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "LocalityMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "LocalityMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<LocalityMappingsQuery, LocalityMappingsQueryVariables>;
export const ClinicCategoryMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "clinicCategoryMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "ClinicCategoryMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "ClinicCategoryMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "clinicCategoryMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "ClinicCategoryMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "ClinicCategoryMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "ClinicCategoryMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<ClinicCategoryMappingsQuery, ClinicCategoryMappingsQueryVariables>;
export const BrickMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "BrickMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "BrickMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "BrickMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "brickMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "BrickMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "BrickMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "BrickMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<BrickMappingsQuery, BrickMappingsQueryVariables>;
export const PersonStatusMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "PersonStatusMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "PersonStatusMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "PersonStatusMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "personStatusMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "PersonStatusMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "PersonStatusMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "PersonStatusMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "clientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "alternativeClientValue"}
      }, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<PersonStatusMappingsQuery, PersonStatusMappingsQueryVariables>;
export const OrganizationStatusMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "OrganizationStatusMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "OrganizationStatusMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "OrganizationStatusMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "organizationStatusMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "OrganizationStatusMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "OrganizationStatusMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "OrganizationStatusMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "names"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "nameRus"}}]
        }
      }]
    }
  }]
} as unknown as DocumentNode<OrganizationStatusMappingsQuery, OrganizationStatusMappingsQueryVariables>;
export const PersonMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "PersonMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "PersonMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "PersonMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "personMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "PersonMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "PersonMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "PersonMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<PersonMappingsQuery, PersonMappingsQueryVariables>;
export const OrganizationMappingsDocument = {
  "kind": "Document", "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {"kind": "Name", "value": "OrganizationMappings"},
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "1"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}},
      "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "Int"}},
      "defaultValue": {"kind": "IntValue", "value": "0"}
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}},
      "type": {
        "kind": "NonNullType",
        "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "OrganizationMappingFilterInput"}}
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}},
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {"kind": "NamedType", "name": {"kind": "Name", "value": "OrganizationMappingSortInput"}}
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {"kind": "Name", "value": "organizationMappings"},
        "arguments": [{
          "kind": "Argument",
          "name": {"kind": "Name", "value": "where"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "where"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "take"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "take"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "skip"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "skip"}}
        }, {
          "kind": "Argument",
          "name": {"kind": "Name", "value": "order"},
          "value": {"kind": "Variable", "name": {"kind": "Name", "value": "order"}}
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {"kind": "Name", "value": "items"},
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{"kind": "FragmentSpread", "name": {"kind": "Name", "value": "OrganizationMapping"}}]
            }
          }, {"kind": "Field", "name": {"kind": "Name", "value": "totalCount"}}]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {"kind": "Name", "value": "OrganizationMapping"},
    "typeCondition": {"kind": "NamedType", "name": {"kind": "Name", "value": "OrganizationMapping"}},
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "id"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "entityId"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "createdAt"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "clientId"}
      }, {
        "kind": "Field",
        "alias": {"kind": "Name", "value": "clientValue"},
        "name": {"kind": "Name", "value": "clietnValue"}
      }, {"kind": "Field", "name": {"kind": "Name", "value": "alternativeClientValue"}}, {
        "kind": "Field",
        "name": {"kind": "Name", "value": "client"},
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{"kind": "Field", "name": {"kind": "Name", "value": "name"}}, {
            "kind": "Field",
            "name": {"kind": "Name", "value": "id"}
          }]
        }
      }, {"kind": "Field", "name": {"kind": "Name", "value": "entityDescription"}}]
    }
  }]
} as unknown as DocumentNode<OrganizationMappingsQuery, OrganizationMappingsQueryVariables>;
