import {getApiConfiguration} from "../../../../../../core";
import {BoundDto, BrickDto, BricksApi,} from "../../../../../../services/management";
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import {MappingTableStore} from "./MappingTableStore";
import {MappingsStore} from "./MappingsStore";
import {getBrickAddressHandler} from "../../../../../utils";
import {DefaultFormModalStore} from "../../../../../../components/shared/DefaultFormModalStore";
import {
    BrickMappingFragment,
    BrickMappingsQuery,
    BrickMappingsQueryVariables,
    RemoveBrickMappingMutation,
    RemoveBrickMappingMutationVariables,
    SetBrickMappingMutation,
    SetBrickMappingMutationVariables,
} from "../../../../../../gql/graphql";
import {getAppoloClient, GRAPHQL_URLS} from "../../../../../../graphql";
import {BRICK_MAPPING_QUERY} from "../../../../../../graphql/queries.graphql";
import {
    REMOVE_BRICK_MAPPING_MUTATION,
    SET_BRICK_STATUS_MAPPING_MUTATION
} from "../../../../../../graphql/mutations.graphql";
import {jurisdictionId} from "../../../../../authorization/AuthorizationStore";

export class BrickTableStore extends MappingTableStore<BrickMappingFragment> {
    constructor(store: MappingsStore, dto: BoundDto) {
        super(store, dto, 'client-mappings-brick-store');

        this.creator.fields.entity.nameExp = (x) => getBrickAddressHandler(x)
        this.creator.fields.entity.defaultSort = true;
        this.creator.fields.entity.required = true;
        this.creator.fields.value.required = true;
    }

    creator: DefaultFormModalStore<{ value: DefaultValueStore<string>; entity: DefaultSelectStore<any> }> =
        new DefaultFormModalStore({
            entity: new DefaultSelectStore<BrickDto>(
                null, (x) => new BricksApi(getApiConfiguration()).getBricks({
                    ...x,
                    orderBy: 'nameRus',
                    order: 'ascending'
                }),
                null, 'value',
                (x) => getBrickAddressHandler(x)),
            value: new DefaultValueStore<string>("")
        }, model => this.create(), );

    async getMappings(filters: any): Promise<{ items: { item: BrickMappingFragment; name: string }[]; count: number }> {
        const {data} = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).query<BrickMappingsQuery, BrickMappingsQueryVariables>({
            query: BRICK_MAPPING_QUERY, variables: filters,
        })

        return {
            items: (data.brickMappings?.items ?? []).map(x => ({item: x, name: x.names?.nameRus ?? "" })),
            count: data.brickMappings?.totalCount ?? 0
        }
    }

    async removeMapping(filter: { boundId: string; setMappingDto: Array<{ clientValue: string; entityId: string }> }): Promise<void> {
        for (let val of filter.setMappingDto) {
            await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<RemoveBrickMappingMutation, RemoveBrickMappingMutationVariables>({
                mutation: REMOVE_BRICK_MAPPING_MUTATION, variables: {
                    clientId: val.clientValue,
                    entityId: val.entityId,
                    jurisdictionId: jurisdictionId
                }
            })
        }
    }

    async setMapping(filter: { boundId: string; setMappingDto: Array<{ clientValue: string; entityId: string }> }): Promise<{ item: BrickMappingFragment; name: string }[]> {
        const list: { item: BrickMappingFragment; name: string }[] = [];
        for (let val of filter.setMappingDto) {
            const item = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<SetBrickMappingMutation, SetBrickMappingMutationVariables>({
                mutation: SET_BRICK_STATUS_MAPPING_MUTATION, variables: {
                    entityId: val.entityId!,
                    clientValue: val.clientValue,
                    clientId: filter.boundId,
                    jurisdictionId: jurisdictionId
                }
            })
            list.push(({item: item.data?.setBrickMapping!, name: item.data?.setBrickMapping.names?.nameRus ?? ""}))
        }
        return list;
    }
}
