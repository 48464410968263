import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IExpanded, ILoad, IProcessing, ISelected} from "../../../../../../../../core";
import {
    ClientLocalitiesApi,
    GetBoundLocalitiesRequest,
    GetLocalitiesRequest,
    LocalitiesApi,
    LocalityDto,
    QueryType
} from "../../../../../../../../services/management";
import {LocalityStore} from "./LocalityStore";
import {BoundsStore} from "../BoundsStore";
import {ClientFragment} from "../../../../../../../../gql/graphql";

type LocalityNode = LocalityDto & ISelected & IExpanded & { children: LocalityNode[]}

export class LocalityTableStore implements IProcessing, ILoad, IExpanded {
    readonly store: BoundsStore;
    readonly _dto: ClientFragment;
    processing: boolean = false;
    loading: boolean = false;
    private _search: string = "";
    private _expand: boolean = false;

    saveLocalities: LocalityDto[] = [];

    defaultTree: LocalityNode[] = [];

    _localities: LocalityStore[] = [];

    constructor(store: BoundsStore, dto: ClientFragment) {
        this.store = store;
        this._dto = dto;
        makeAutoObservable(this);
    }

    get expand(): boolean{
        return this._expand;
    }

    set expand(value: boolean){
        this._expand = value;
        if(this.expand)
            this.loadData()
    }

    get search() {
        return this._search
    }

    get saveLocalitiesIds(): string[] {
        return this.saveLocalities.map(x => x.id);
    }

    get newSelectedItems() {
        return this.selectedLocalityIds.filter(x => this.saveLocalitiesIds.indexOf(x) == -1);
    }

    get newUnSelectedItems() {
        return this.saveLocalitiesIds.filter(x => this.selectedLocalityIds.indexOf(x) == -1)
    }

    get equals() {
        return this.newSelectedItems.length == 0 && this.newUnSelectedItems.length == 0
    }

    get selectedLocalityIds(): string[] {
        return this._localities.map(x => x.selectedChildren).reduce(function (x, y) {
            return x.concat(y);
        }, []).concat(this._localities.filter(x => x.selected)).map(x => x.id)
    }

    get allLocalityVM(): LocalityStore[] {
        return this._localities.map(x => x.allChildren).reduce(function (x, y) {
            return x.concat(y);
        }, []).concat(this._localities)
    }

    async loadData(): Promise<void> {
        if (this._localities.length > 0)
            return;
        this.loading = true;
        await this.loadSelected();
        if (!!this.saveLocalities?.length) {
            this.defaultTree = this.getTreeLocalities(this.saveLocalities)
            this.firstInit();
        } else
            await this.pull();

        this.loading = false;
    }

    async setSearch(value: string): Promise<void> {
        this._search = value;
        await this.pull();
    }

    async loadSelected(): Promise<void> {
        const filters = {
            page: 1,
            boundId: this.store.id,
            size: 1000,
            jurisdictionId: this.store.jurisdiction.id
        } as GetBoundLocalitiesRequest;
        const api: ClientLocalitiesApi = new ClientLocalitiesApi(getApiConfiguration());
        this.saveLocalities = (await api.getBoundLocalities(filters)).map(x => x.locality);
    }

    getTreeLocalities(locations: LocalityDto[]): LocalityNode[] {
        if (locations.length == 0)
            return [];

        let tree: LocalityNode[] = [];

        function getNodeById(nodes: LocalityNode, id: string) {
            if (nodes.id === id) {
                return nodes;
            } else if (Array.isArray(nodes.children)) {
                let result = null;
                nodes.children.forEach(node => {
                    if (!!getNodeById(node, id)) {
                        result = getNodeById(node, id);
                    }
                });
                return result;
            }
            return null;
        }

        for (let i = 0; i < locations.length; i++) {
            let locality = locations[i] as LocalityNode;
            if (this.saveLocalitiesIds.indexOf(locality.id) > -1)
                locality.selected = true;

            let hasParent: boolean = !!locality.parentId && locality.parentId != locality.id;

            while (hasParent) {
                let parent = tree[0] ? getNodeById(tree[0], locality.parentId!) : null;
                if (parent) {
                    if (!!parent?.children) {
                        parent.children.push(locality)
                    } else {
                        parent.children = [locality]
                    }
                    break;
                } else {
                    parent = locality.parent as LocalityNode;
                    parent.children = [locality]
                    locality = parent;
                    hasParent = !!locality.parentId && locality.parentId != locality.id;
                }
            }

            if (!i)
                tree.push(locality as LocalityNode);
        }
        return tree;
    }

    firstInit() {
        function getLocalityVM(node: LocalityNode, parent?: LocalityStore): LocalityStore {
            let vm = new LocalityStore(node, parent)
            vm.setSelected(node.selected, false, false)
            vm._children = node.children?.map(x => getLocalityVM(x, vm)) ?? undefined
            return vm;
        }

        this._localities = this.defaultTree.map(x => getLocalityVM(x, undefined));
    }

    reset() {
        this.allLocalityVM.forEach(x => x.setSelected(this.saveLocalitiesIds.indexOf(x.id) > -1, false, false))
    }

    async pull(): Promise<void> {
        this.processing = true;
        const config = getApiConfiguration();
        config.headers!['Jurisdiction'] = this.store.jurisdiction.id!;

        const api: LocalitiesApi = new LocalitiesApi(config);
        this.processing = true;
        const filters = {
            queryType: QueryType.Tree,
            parentId: undefined,
            order: "ascending",
            page: 1,
            size: 100,
        } as GetLocalitiesRequest;

        try {
            const items = await api.getLocalities(filters);
            this._localities = items.map(x => new LocalityStore(x, undefined))
            this.processing = false;
        } catch (e) {
            this.processing = false;
        }
    };

    async save(): Promise<void> {
        this.processing = true;
        try {
            let newItem = this.saveLocalities;
            if (this.newUnSelectedItems.length > 0) {
                await new ClientLocalitiesApi(getApiConfiguration()).removeBoundLocalities({
                    boundId: this._dto.id,
                    requestBody: this.newUnSelectedItems
                })
                newItem = newItem.filter(x => this.newUnSelectedItems.indexOf(x.id) == -1);
            }

            if (this.newSelectedItems.length > 0) {
                let result = await new ClientLocalitiesApi(getApiConfiguration()).setBoundLocalities({
                    boundId: this._dto.id,
                    requestBody: this.newSelectedItems
                })
                newItem = newItem.concat(result.map(x => x.locality))
            }

            this.saveLocalities = newItem;

            this.processing = false;

        } catch (e) {
            this.processing = false;
        }
    }
}

