import {Box} from "@mui/material";
import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, FormRow, ParamsButtonGroup} from "../../../components";
import {DefaultMultiSelect} from "../../../components/shared/components/DefaultMultiSelect";
import {DefaultSelect} from "../../../components/shared/components/DefaultSelect";
import {AllPermissions} from "../../../core/const";
import {useSettings} from "../../../core/hooks/use-settings";
import {JobItemStore} from "../../../stores/management/persons/tabs/jobs/JobItemStore";
import {callTheProcessingFunc} from "../../../stores";

interface Props {
    job: JobItemStore;
    style?: React.CSSProperties
}

export const JobDetails = observer(({job, style}: Props) => {
    const {t} = useTranslation();
    const settings = useSettings();

    return (
        <Box style={style} sx={{py: 2, pr: 0, pl: 3}}>
            <FormRow>
                <DefaultSelect store={job.personStatus}
                               disabled={job.store.isDisable}
                               testid="jobDetails-personStatus"
                               title={t('common.status')}
                               style={{marginRight: 16}}
                />
                <DefaultSelect store={job.postJob}
                               disabled={job.store.isDisable}
                               testid="jobDetails-postJob"
                               title={t('common.post')}
                />
            </FormRow>
            <Box>
                <DefaultMultiSelect store={job.tagsControl}
                                    testid="tags-parameters"
                                    disabled={job.store.isDisable}
                                    title={t('common.tags')}
                                    tags
                                    valueExp="id"
                />
            </Box>
            <AuthorizePermission permissions={AllPermissions.person.personjobs.update}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <ParamsButtonGroup
                        testid="jobDetails"
                        onCancel={() => job.restore()}
                        onSave={async () => await callTheProcessingFunc(job, job.save.bind(job), null, () => job.restore())()}
                        isCancelBtnDisabled={job.processing || job.equals}
                        isSaveBtnDisabled={job.processing || job.equals || !job.valid || job.store.isDisable}
                    />
                </Box>
            </AuthorizePermission>
        </Box>
    );
});
