import {observer} from "mobx-react";
import {AuthorizePermission, PageBody, ParamsButtonGroup, Progress} from "../../../../../components";
import React from "react";
import {TreeView} from '@mui/x-tree-view/TreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import {LocalityStore} from "../../../../../stores/management/clients/client/tabs/bounds/items/Locality/LocalityStore";
import {
    LocalityTableStore
} from "../../../../../stores/management/clients/client/tabs/bounds/items/Locality/LocalityTableStore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Box, Checkbox, FormControlLabel} from "@mui/material";
import {MbEmptyComponent} from "../../../../../components/MbEmptyComponent";
import {AllPermissions} from "../../../../../core/const";

const StyledTreeItem = observer((props: { store: LocalityStore }) => (
    <TreeItem
        key={props.store.id}
        nodeId={props.store.id}
        onClick={async (e: any) => {
            props.store.expand = !props.store.expand
            if (props.store.expand)
                await props.store.pull()
        }}
        label={
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.store.selected}
                        indeterminate={props.store.indeterminate}
                        style={{marginLeft: 5}}
                        onChange={async event => {
                            props.store.setSelected(event.target.checked)
                        }}
                        onClick={e => e.stopPropagation()}
                    />
                }
                label={<div onClick={e => e.stopPropagation()}>{props.store.name}</div>}
                key={props.store.id}
            />
        }
    >
        <TreeItem nodeId={props.store.id + "load"}/>
        {Array.isArray(props.store._children) && !props.store.loading
            ? props.store._children.map(node =>
                <StyledTreeItem store={node}/>)
            : null}
    </TreeItem>
));


interface Props {
    store: LocalityTableStore;
    style?: React.CSSProperties
}

export const LocalityTable = observer(({store, style}: Props) => {
    return (
        <Box sx={{minHeight: '250px', display: "grid"}}>
            <PageBody style={{display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
                <MbEmptyComponent
                    isEmpty={store._localities.length == 0 && !store.loading}
                    position={'relative'}
                    size={150}>
                    <TreeView
                        aria-label="customized"
                        defaultCollapseIcon={<ExpandMoreIcon/>}
                        defaultExpandIcon={<ChevronRightIcon/>}
                        sx={{maxHeight: '500px', flexGrow: 1, width: '100%', minWidth: '200px'}}
                    >
                        {
                            store._localities?.map(x =>
                                <StyledTreeItem store={x}/>
                            )
                        }
                    </TreeView>
                    {!store._localities.length && <Box
                        sx={{
                            flexGrow: 1,
                            py: 3,
                        }}
                    >
                        <Progress store={store}/>
                    </Box>
                    }
                </MbEmptyComponent>
            </PageBody>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 'auto'
                }}
            >
                <AuthorizePermission permissions={AllPermissions.client.update}>
                    <ParamsButtonGroup
                        testid="localityTableBound"
                        onCancel={() => store.reset()}
                        onSave={async () => await store.save()}
                        isCancelBtnDisabled={store.processing || store.equals}
                        isSaveBtnDisabled={store.processing || store.equals}
                    />
                </AuthorizePermission>


            </Box>
        </Box>
    );
});
