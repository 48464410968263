import {makeAutoObservable} from "mobx";
import {ClientItemStore} from "../ClientItemStore";
import {getObject, IHasTabs, setObject} from "../../../../core";
import {MappingsStore} from "./tabs/mappings/MappingsStore";
import {ClientFragment} from "../../../../gql/graphql";
import {ClientJurisdictionsStore} from "./tabs/ClientJurisdictionsStore";
import {ClientBoundStore} from "./tabs/bounds/ClientBoundStore";

interface ClientStoreLocalFilters {
    tabIndex: number;
}

export class ClientStore implements IHasTabs {
    readonly _store: ClientItemStore;
    dto: ClientFragment;
    id: string;
    private _tabIndex = 0;

    bounds: ClientBoundStore;
    mappings: MappingsStore;
    jurisdictionsStore: ClientJurisdictionsStore;

    constructor(store: ClientItemStore, dto: ClientFragment) {
        this._store = store;
        this.dto = dto;
        this.id = dto.id;
        const filters = getObject<ClientStoreLocalFilters>(`client-store-${this.id}`);

        this.bounds = new ClientBoundStore(this);
        this.mappings = new MappingsStore(this, dto);
        this.jurisdictionsStore = new ClientJurisdictionsStore(dto);

        makeAutoObservable(this);
    }

    get tabIndex() {
        return this._tabIndex;
    }

    set tabIndex(value: number) {
        this._tabIndex = value;
        this.saveFilters();
        this.updateState()
    }

    async updateState() {
        if (this.tabIndex == 0) {
            await this.bounds.pull()
        }
        if (this.tabIndex == 2) {
            await this.jurisdictionsStore.pull()
        }
    }

    get orgName() {
        return this.dto.orgName
    }

    update(dto: ClientFragment) {
        this.dto = dto;
    }

    saveFilters() {
        setObject<ClientStoreLocalFilters>(`client-store-${this.id}`, {
            tabIndex: this._tabIndex,
        });
    }
}

