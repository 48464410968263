import {observer} from "mobx-react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {LocalityTable} from "./LocalityTable";
import {useTranslation} from "react-i18next";
import {BoundsStore} from "../../../../../stores/management/clients/client/tabs/bounds/items/BoundsStore";
import {SpecialitiesTable} from "./SpecialitiesTable";
import {TypeOrganizationTable} from "./TypeOrganizationTable";
import {StoreProps, useRootStore} from "../../../../../stores";
import {ClientBoundStore} from "../../../../../stores/management/clients/client/tabs/bounds/ClientBoundStore";
import {Page, PageTab, PageTabs} from "../../../../../components";
import {PersonParams as PersonParamsIcon} from "../../../../../icons/personParams";
import {Layers as LayersIcon} from "../../../../../icons/layers";
import {PersonSpeciality as PersonSpecialityIcon} from "../../../../../icons/personSpeciality";
import React from "react";

interface AccordionTitleProps {
    title: string,
    isExpand: boolean
    style?: React.CSSProperties
}


export const JurisdictionBounds = observer(({store}: StoreProps<BoundsStore>) => {
    const {t} = useTranslation();

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return <LocalityTable store={store.localityStore}/>;
            case 1:
                return <TypeOrganizationTable store={store.typeOrganizationStore}/>
            case 2:
                return <SpecialitiesTable store={store.specialityStore}/>;
            default:
                return null;
        }
    };

    return (
        <Page>
            <PageTabs
                index={store.tabIndex}
                onChange={t => store.tabIndex = t}>
                <PageTab icon={<PersonParamsIcon/>} title={t('common.Region')}/>
                <PageTab icon={<LayersIcon/>} title={t('common.typeOrg')}/>
                <PageTab icon={<PersonSpecialityIcon/>} title={t('common.speciality')}/>
            </PageTabs>
            <div style={{marginTop: 4, marginBottom: 4, padding: 15}}>
                {renderTabs(store.tabIndex)}
            </div>
        </Page>
    )
})

export const AccordionTitle = (props: AccordionTitleProps) => {
    const {themeColorStore} = useRootStore();
    const activeText = themeColorStore.themeSettings === 'dark' ? {color: '#7582EB'} : {color: '#5048E5'}

    const {title, isExpand} = props;
    return <Typography style={{...isExpand ? activeText : undefined, ...props.style}}>{title}</Typography>
}

export const Bounds = observer(({store}: StoreProps<ClientBoundStore>) => {

    return (
        <div style={{width: "100%"}}>
            {
                store.value.map((x, i) => (
                    <Accordion key={i} expanded={x.expand}
                               onChange={(e, isExpanded) => x.expand = isExpanded}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <AccordionTitle isExpand={x.expand} title={x.jurisdiction.name!}/>
                        </AccordionSummary>
                        <AccordionDetails>
                            <JurisdictionBounds store={x}></JurisdictionBounds>
                        </AccordionDetails>
                    </Accordion>))
            }
        </div>
    );
});
