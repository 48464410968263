import {computed, makeObservable} from "mobx";
import {getApiConfiguration, getObject, setObject} from "../../../../core";
import {PagedItems, TableLocalFilters, TableStore} from "../../../utils";
import {ManagementStore} from "../../ManagementStore";
import {RequestItemStore} from "./RequestItemStore";
import {ClientApi, ClientDto, DcrGroupListDto, GroupApi, RequestApi, RequestDto,} from "../../../../services/dcr";
import {DefaultMultiSelectStore} from "../../../../components/shared/DefaultMultiSelectStore";
import {getEnumValues} from "../../../../core/getEnumValues";
import {
    mappingDcrStatusToString,
    mappingRequestCorrectToString,
    mappingRequestStatusToString,
    mappingRequestTypeToString,
} from "../services";
import {DefaultSelectStore} from "../../../../components/shared/DefaultSelectStore";
import {UserDto, UsersApi} from "../../../../services/management";
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {DCRStatusEnum, RequestCorrect, RequestStatusEnum, RequestTypeEnum} from "../types";
import {RequestCommentsStore} from "./RequestCommentsStore";

interface RequestTableStoreLocalFilters extends TableLocalFilters<RequestItemStore> {
    isOpenInDrawer: boolean
    isAcceptedByMe: boolean
    isOpenFiler: boolean
    isEscalation: boolean
}

export class RequestTableStore extends TableStore<RequestItemStore> {
    readonly store: ManagementStore;
    readonly idValue: DefaultValueStore<string>;
    readonly externalIdValue: DefaultValueStore<string>;
    readonly crmIdValue: DefaultValueStore<string>;
    readonly entityIdIdValue: DefaultValueStore<string>;
    readonly dcrStatus: DefaultMultiSelectStore<{ value: string, label: string }>;
    readonly requestRecordType: DefaultMultiSelectStore<{ value: string, label: string }>;
    readonly requestStatus: DefaultMultiSelectStore<{ value: string, label: string }>;
    readonly client: DefaultMultiSelectStore<ClientDto>;
    readonly responsible: DefaultMultiSelectStore<UserDto>;
    readonly azType: DefaultMultiSelectStore<string>;
    readonly dcrGroup: DefaultSelectStore<DcrGroupListDto>;
    readonly correctRequest: DefaultSelectStore<{ value: string, label: string }>;
    readonly isAcceptedByMe: DefaultValueStore<boolean>;
    readonly isOpenInDrawer: DefaultValueStore<boolean> = new DefaultValueStore<boolean>(false, null, (e) => this.onFiltersSave());
    readonly isOpenFiler: DefaultValueStore<boolean> = new DefaultValueStore<boolean>(true, null, (e) => this.onFiltersSave());
    readonly isEscalation: DefaultValueStore<boolean>;
    readonly startDate: DefaultValueStore<Date>;
    readonly endDate: DefaultValueStore<Date>;
    readonly commentModel: RequestCommentsStore = new RequestCommentsStore(this);

    constructor(store: ManagementStore) {
        super();
        const filters = getObject<RequestTableStoreLocalFilters>('RequestTable-store');

        this.idValue = new DefaultValueStore<string>("", null, async (e) => await this.updateState());
        this.entityIdIdValue = new DefaultValueStore<string>("", null, async (e) => await this.updateState());
        this.externalIdValue = new DefaultValueStore<string>("", null, async (e) => await this.updateState());
        this.crmIdValue = new DefaultValueStore<string>("", null, async (e) => await this.updateState());
        this.startDate = new DefaultValueStore<Date>(null, null, async (e) => await this.updateState());
        this.endDate = new DefaultValueStore<Date>(null, null, async (e) => await this.updateState());
        this.isAcceptedByMe = new DefaultValueStore<boolean>(filters?.isAcceptedByMe ?? false, null, async (e) => await this.updateState());
        this.isEscalation = new DefaultValueStore<boolean>(filters?.isEscalation ?? false, null, async (e) => await this.updateState());

        this.client = new DefaultMultiSelectStore<ClientDto>([],
            (e) => new ClientApi().apiDcrV1ClientsGet(e),
            async (e) => await this.updateState(),
            null,
            "name",
            "name")

        this.azType = new DefaultMultiSelectStore<string>([],
            (e) => new ClientApi(getApiConfiguration() as any).apiDcrV1ClientsAzTypesGet(),
            async (e) => await this.updateState(),
            null,
            "",
            (e) => e)

        this.dcrStatus = new DefaultMultiSelectStore<{ value: string; label: string }>([],
            (e) => Promise.resolve(getEnumValues(DCRStatusEnum)),
            async (e) => await this.updateState(),
            null,
            "value",
            (e) => mappingDcrStatusToString(parseInt(e.value)))

        this.correctRequest = new DefaultSelectStore<{ value: string; label: string }>(null,
            (e) => Promise.resolve(getEnumValues(RequestCorrect)),
            null,
            'value',
            (e) => mappingRequestCorrectToString(parseInt(e.value)))
        this.correctRequest.defaultFirstValue = true;
        this.correctRequest.onChanged.push(async (e) => await this.updateState())

        this.requestRecordType = new DefaultMultiSelectStore<{ value: string; label: string }>([],
            (e) => Promise.resolve(getEnumValues(RequestTypeEnum)),
            async (e) => await this.updateState(),
            null,
            "value",
            (e) => mappingRequestTypeToString(parseInt(e.value)))
        this.requestStatus = new DefaultMultiSelectStore<{ value: string; label: string }>([],
            (e) => Promise.resolve(getEnumValues(RequestStatusEnum)),
            async (e) => await this.updateState(),
            null,
            "value",
            (e) => mappingRequestStatusToString(parseInt(e.value)))
        this.dcrGroup = new DefaultSelectStore<DcrGroupListDto>(
            null,
            (e) => new GroupApi(getApiConfiguration() as any).getGroups({userId: this.store!.store!.authorizationStore!.user?._store!.id}).then(x => x.items!))
        this.dcrGroup.defaultFirstValue = true;
        this.dcrGroup.onChanged.push(async (e) => {
            this.isEscalation.setValueWithoutEffects(false);
            await this.updateState()
        })

        this.responsible = new DefaultMultiSelectStore([],
            (x) => new UsersApi(getApiConfiguration()).getUsers(x),
            async (e) => await this.updateState(),
            null,
            "name",
            "name")

        this.store = store;

        if (filters) {
            this._page = filters.page ?? 0;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);;
            this._order = filters.order;
            this._orderBy = filters.orderBy;
            this.isOpenInDrawer.value = filters.isOpenInDrawer;
            this.isOpenFiler.value = filters.isOpenFiler
        }

        this.store.store.signalRStore.registerSubs("dcr", {
            "RequestUpdate": async (item: RequestDto) => {
                let updateItem = this.items.filter(x => x.dto.id === item.id)[0]
                if (!!updateItem)
                    await updateItem.update(item)
            }
        })

        makeObservable(this, {
            openRequest: computed,
            isOpenDrawer: computed
        });
    }

    get isOpenDrawer(){
        return !!this.items.find(x => x.expand && this.isOpenInDrawer.value)
    }

    get openRequest(){
        return this.items.find(x => x.expand)
    }

    get selectedAz(): boolean {
        return !!this.client.value.find(x => x.name == "AZ") ||
            this.dcrGroup.value?.name?.toLowerCase() == 'astrazeneca' ||
            this.dcrGroup.value?.name?.toLowerCase() == 'az';
    }

    resetFilters(){
        this.idValue.reset()
        this.externalIdValue.reset()
        this.crmIdValue.reset()
        this.entityIdIdValue.reset()
        this.dcrStatus.reset()
        this.requestRecordType.reset()
        this.requestStatus.reset()
        this.client.reset()
        this.responsible.reset()
        this.azType.reset()
        this.correctRequest.reset()
        this.isAcceptedByMe.reset()
        this.isOpenInDrawer.reset()
        this.isOpenFiler.reset()
        this.isEscalation.reset()
        this.startDate.reset()
        this.endDate.reset()
    }

    async request(): Promise<PagedItems<RequestItemStore>> {
        if (this.dcrGroup.items.length == 0)
            await this.dcrGroup.pull()
        if (!this.dcrGroup.value) {
            return new PagedItems<RequestItemStore>([], 0)
        }

        let result = await new RequestApi(getApiConfiguration() as any).apiDcrV1RequestsGet({
            page: this.page + 1,
            take: this.size,
            groupId: this.dcrGroup.value!.id!,
            dcrStatus: this.dcrStatus.value?.length ? this.dcrStatus.value.map(x => parseInt(x.value) as any) : undefined,
            requestStatus: this.requestStatus.value?.length ? this.requestStatus.value.map(x => parseInt(x.value) as any) : undefined,
            requestRecordType: this.requestRecordType.value?.length ? this.requestRecordType.value.map(x => parseInt(x.value) as any) : undefined,
            client: this.client.value?.length ? this.client.value.map(x => x.name!) : undefined,
            responsible: this.responsible.value?.length ? this.responsible.value.map(x => x.id!) : undefined,
            orderField: this.orderBy,
            order: this.order === "ascending" ? 1 : 0,
            searchById: this.idValue.value?.length ? this.idValue.value : undefined,
            searchByExternalId: this.externalIdValue.value?.length ? this.externalIdValue.value : undefined,
            isAcceptedByMe: !!this.isAcceptedByMe.value,
            azType: this.azType.value?.length ? this.azType.value : undefined,
            searchByCrmId: this.crmIdValue.value?.length ? this.crmIdValue.value : undefined,
            startDate: this.startDate.value ? this.startDate.value : undefined,
            endDate: this.endDate.value ? this.endDate.value : undefined,
            isEscalation: !!this.isEscalation.value,
            isOnlyCorrect: (this.correctRequest.value?.value == '-1' || (!this.correctRequest.value?.value && this.correctRequest.value?.value != '0')) ?
                undefined : parseInt(this.correctRequest.value?.value!) == 1,
            searchByData360Id: this.entityIdIdValue.value?.length ? this.entityIdIdValue.value : undefined,
        });
        let newItem = []

        for (const element of (result.items ?? [])) {
            let item = this.items.filter(x => x.dto.id === element.id)[0]
            newItem.push(!!item ? item : new RequestItemStore(this, element))
        }
        return new PagedItems<RequestItemStore>(newItem, result.total)
    };


    onFiltersSave() {
        setObject<RequestTableStoreLocalFilters>('RequestTable-store', {
            search: this.search.value ?? '',
            order: this.order,
            page: this.page,
            size: this.size,
            isOpenInDrawer: this.isOpenInDrawer.value!,
            isAcceptedByMe: this.isAcceptedByMe.value!,
            isOpenFiler: this.isOpenFiler.value!,
            isEscalation: this.isEscalation.value!,
        });
    }
}

