import {getApiConfiguration} from "../../../../../../core";
import {BoundDto, PostDto, PostsApi} from "../../../../../../services/management";
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import {MappingTableStore} from "./MappingTableStore";
import {MappingsStore} from "./MappingsStore";
import i18n from "i18next";
import {DefaultFormModalStore} from "../../../../../../components/shared/DefaultFormModalStore";
import {
    PostMappingFragment,
    PostMappingsQuery,
    PostMappingsQueryVariables,
    RemovePostMappingMutation,
    RemovePostMappingMutationVariables,
    SetPostMappingMutation,
    SetPostMappingMutationVariables
} from "../../../../../../gql/graphql";
import {getAppoloClient, GRAPHQL_URLS} from "../../../../../../graphql";
import {POST_MAPPING_QUERY} from "../../../../../../graphql/queries.graphql";
import {REMOVE_POST_MAPPING_MUTATION, SET_POST_MAPPING_MUTATION} from "../../../../../../graphql/mutations.graphql";
import {jurisdictionId} from "../../../../../authorization/AuthorizationStore";

export class PostTableStore extends MappingTableStore<PostMappingFragment> {
    constructor(store: MappingsStore, dto: BoundDto) {
        super(store, dto, 'client-mappings-post-store');

        this.creator.fields.entity.defaultSort = true;
        this.creator.fields.entity.required = true;
        this.creator.fields.value.required = true;
    }

    creator: DefaultFormModalStore<{ value: DefaultValueStore<string>; entity: DefaultSelectStore<any> }> =
        new DefaultFormModalStore({
            entity: new DefaultSelectStore<PostDto>(null,
                (filters) => new PostsApi(getApiConfiguration()).getPosts({
                    ...filters,
                    orderBy: 'name_rus',
                    order: 'ascending'
                })),
            value: new DefaultValueStore<string>("")
        }, (model) => this.create());

    async getMappings(filters: any): Promise<{ items: { item: PostMappingFragment; name: string }[]; count: number }> {
        const {data} = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).query<PostMappingsQuery, PostMappingsQueryVariables>({
            query: POST_MAPPING_QUERY, variables: filters,
        })

        return {
            items: (data.postMappings?.items ?? []).map(x => ({item: x, name: x.names?.nameRus ?? "" })),
            count: data.postMappings?.totalCount ?? 0
        }
    }

    async removeMapping(filter: { boundId: string; setMappingDto: Array<{ clientValue: string; entityId: string }> }): Promise<void> {
        for (let val of filter.setMappingDto) {
            await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<RemovePostMappingMutation, RemovePostMappingMutationVariables>({
                mutation: REMOVE_POST_MAPPING_MUTATION, variables: {
                    clientId: val.clientValue,
                    entityId: val.entityId,
                    jurisdictionId: jurisdictionId
                }
            })
        }
    }

    async setMapping(filter: { boundId: string; setMappingDto: Array<{ clientValue: string; entityId: string }> }): Promise<{ item: PostMappingFragment; name: string }[]> {
        const list: { item: PostMappingFragment; name: string }[] = [];
        for (let val of filter.setMappingDto) {
            const item = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<SetPostMappingMutation, SetPostMappingMutationVariables>({
                mutation: SET_POST_MAPPING_MUTATION, variables: {
                    entityId: val.entityId!,
                    clientValue: val.clientValue,
                    clientId: filter.boundId,
                    jurisdictionId: jurisdictionId
                }
            })
            list.push(({item: item.data?.setPostMapping!, name: item.data?.setPostMapping.names?.nameRus ?? ""}))
        }
        return list;
    }
}
