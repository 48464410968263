import {makeAutoObservable} from 'mobx';
import {JobsTableStore} from "./JobsTableStore";
import {
    AssignedPersonDepJobTagsApi,
    AssignedPersonJobTagsApi,
    DepJobsApi,
    JobsApi,
    JobStatusesApi,
    JobTagsApi,
    PersonJobDto,
    PersonStatusDto,
    PostDto,
    PostsApi,
    TagDto
} from "../../../../../services/management";
import {getOrganizationAddressHandler, getOrgName} from '../../../../utils';
import {getApiConfiguration, IExpanded, IProcessing, toDateTimeString} from "../../../../../core";
import {DefaultSelectStore} from '../../../../../components/shared/DefaultSelectStore';
import i18n from "i18next";
import {DefaultMultiSelectStore} from '../../../../../components/shared/DefaultMultiSelectStore';

export class JobItemStore implements IProcessing, IExpanded {
    readonly store: JobsTableStore;
    dto: PersonJobDto;
    personStatus: DefaultSelectStore<PersonStatusDto>;
    postJob: DefaultSelectStore<PostDto>;
    processing: boolean = false;
    expand: boolean = false;
    id: string;
    tagsControl: DefaultMultiSelectStore<TagDto>;

    constructor(store: JobsTableStore, dto: PersonJobDto) {
        this.id = dto.id;
        this.store = store;
        this.dto = dto;

        this.personStatus = new DefaultSelectStore(dto.status as PersonStatusDto,
            (filters) => new JobStatusesApi(getApiConfiguration()).getJobStatuses({...filters}),
            (value) => !value ? i18n.t('errors.empty') : "");
        this.personStatus.required = true;

        this.postJob = new DefaultSelectStore(dto.post as PostDto,
            (filters) => new PostsApi(getApiConfiguration()).getPosts({
                ...filters,
                orderBy: 'name_rus',
                order: 'ascending',
                showOnlyActive: true
            }));
        this.postJob.required = true;

        this.tagsControl = new DefaultMultiSelectStore(this.dto.tags ?? [],
            (filters) => new JobTagsApi(getApiConfiguration()).getJobTags(filters),
        );

        makeAutoObservable(this);
    }

    get address(): string {
        return getOrganizationAddressHandler(this.dto.organization?.addressUnique);
    }

    get isTypeDepartment(): boolean {
        return this.store.typeDepartmentList?.includes(this.dto.organization?.typeOrgId)
    }

    get organizationName() {
        return getOrgName(this.dto.organization)
    }

    get sorted() {
        return this.dto?.modifiedAt;
    }

    get createDate() {
        if (this.dto?.createdAt) {
            return toDateTimeString(this.dto?.createdAt);
        }
        return '';
    }

    get date() {
        if (this.dto?.modifiedAt) {
            return toDateTimeString(this.dto?.modifiedAt);
        }
        return '';
    }

    async remove() {
        this.isTypeDepartment ? await new DepJobsApi(getApiConfiguration()).deletePersonDepJob({id: this.id}) :
            await new JobsApi(getApiConfiguration()).deletePersonJob({id: this.id})
    }

    get valid(): boolean {
        return this.personStatus.valid && this.postJob.valid;
    }

    get equals(): boolean {
        return this.id === this.dto.id &&
            this.personStatus.equals &&
            this.postJob.equals &&
            this.tagsControl.equals
    }

    validate() {
        this.personStatus.validate();
        this.postJob.validate();
    }

    restore() {
        this.personStatus.reset();
        this.postJob.reset();
        this.tagsControl.reset()
        this.validate();
    }

    get disabled(): boolean {
        return this.store.items.length < 2
    }

    get associatedDepJob(): JobItemStore | null {
        return !this.isTypeDepartment ? this.store.items?.filter(x => x.isTypeDepartment && this.dto.organization.id === x.dto.organization.parentId)?.[0] : null;
    }

    async saveTags() {
        if(!this.tagsControl.equals){
            const result = this.isTypeDepartment ?
                await new AssignedPersonDepJobTagsApi(getApiConfiguration({successTooltip: false}))
                    .setAssignedDepJobTags({
                        depJobId: this.id,
                        requestBody: this.tagsControl?.value?.map(x => x.id) ?? []
                    }) :
                await new AssignedPersonJobTagsApi(getApiConfiguration({successTooltip: false}))
                    .setAssignedJobTags({
                        jobId: this.id,
                        requestBody: this.tagsControl?.value?.map(x => x.id) ?? []
                    });

            this.dto.tags = result.tags;
            this.tagsControl.update(this.dto.tags ?? [])
        }
    }


    async updateTick() {
        const a =
        this.isTypeDepartment ?
            await new DepJobsApi().tickDepJob({id: this.dto.id}) :
            await new JobsApi().tickJob({id: this.dto.id});

        this.dto.modifiedAt = a.modifiedAt;
        this.dto.ticks = a.ticks;
    }

    update(dto: PersonJobDto) {
        this.dto = dto;
        this.personStatus.update(this.dto.status)
        this.postJob.update(this.dto.post)
    }


    async save(): Promise<void> {
        await this.saveTags()

        if (this.isTypeDepartment) {
            const result = await new DepJobsApi(getApiConfiguration()).updatePersonDepJob({
                id: this.id,
                updatePersonDepJobDto: {
                    postId: this.postJob.value?.id ?? this.dto.post.id,
                    statusId: this.personStatus.value?.id,
                }
            })
        } else {
            const result = await new JobsApi(getApiConfiguration()).updatePersonJob({
                id: this.id,
                updatePersonJobDto: {
                    postId: this.postJob.value?.id ?? this.dto.post.id,
                    statusId: this.personStatus.value?.id,
                }
            })
        }

        await this.store.store.requestPerson()
    }
}

