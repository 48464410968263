import {computed, makeObservable, observable} from 'mobx';
import {PagedItems, TableLocalFilters, TableStore} from '../../../../../../utils';
import {getObject, IExpanded, ISelected} from "../../../../../../../core";
import {
    BoundDto,
    GetBoundClinicCategoriesRequest,
    GetClinicCategoriesCountRequest
} from "../../../../../../../services/management";
import {BoundsStore} from "./BoundsStore";

export abstract class BoundTableStore extends TableStore<ISelected & { name: string, id: string | number }> {
    readonly store: BoundsStore;
    readonly _dto: BoundDto;
    _expand: boolean = false;

    filterName: string;
    defaultSelectedIds: string[] = [];
    _selectedIds: any[] = [];

    abstract getSelectedIds(filters: any): Promise<string[]>;
    abstract getEntities(filters: any): Promise<{items: (ISelected & {name: string, id: string | number})[], count: number }>;
    abstract removeBound(filter: {boundId: string, requestBody: Array<string>}): Promise<void>
    abstract setBound(filter: {boundId: string, requestBody: Array<string>}): Promise<void>

    protected constructor(store: BoundsStore, dto: BoundDto, filterName: string) {
        super();
        this.filterName = filterName;
        this.store = store;
        this._dto = dto;
        const filters = getObject<TableLocalFilters<ISelected & {name: string}> & IExpanded>(filterName + '-' + this._dto.id);
        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);;
            this._order = filters.order;
            this._orderBy = filters.orderBy;
            this._expand = filters.expand
        }
        makeObservable(this, {
            _expand: observable,
            _selectedIds: observable,
            selectedIds: computed,
            defaultSelectedIds: observable,
        });
    }

    changeChecked(value: any) {
        if(this.selectedIds.includes(value)) return this.selectedIds = this.selectedIds.filter(x => x !== value)
        return this.selectedIds = Array.from(new Set([...this.selectedIds, value]))
    }

    get selectedIds(): any[] {
        return this._selectedIds;
    }

    set selectedIds(value: any[]){
        this._selectedIds = value;
    }

    get newSelectedItems() {
        return this.selectedIds.filter(x => !this.defaultSelectedIds.includes(x));
    }

    get newUnSelectedItems() {
        return this.defaultSelectedIds.filter(x => !this.selectedIds.includes(x))
    }

    get equals(){
        return this.newSelectedItems.length == 0 && this.newUnSelectedItems.length == 0
    }

    reset(){
        this.items.forEach(x => x.selected = this.selectedIds.indexOf(x.id) > -1)
        // this.items.forEach(x => x.selected = this.selectedIds.indexOf(x.id) > -1)
    }

    async loadData(): Promise<void>{
        this.loading = true;
        await this.loadSelectedIds(this.count);
        this.selectedIds = Array.from(new Set([...this.selectedIds, ...this.defaultSelectedIds]));
        await this.pull();
        this.reset()
        this.loading = false;
    }

    async loadSelectedIds(size?: number): Promise<void>{
        const filters = {
            page: 1,
            boundId: this.store.id,
            size: size || 1000,
            jurisdictionId: this.store.jurisdiction.id
        } as GetBoundClinicCategoriesRequest;
        this.defaultSelectedIds = await this.getSelectedIds(filters);
    }

    async afterLoad(): Promise<void> {
        this.reset()
    }

    async request(): Promise<PagedItems<ISelected & {name: string, id: string | number}>> {
        const filters = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy
        } as GetClinicCategoriesCountRequest;

        const {count,items} = await this.getEntities(filters);

        return new PagedItems(items,count)
    };

    async save(): Promise<void>{
        this.processing = true;
        try {

            if(this.newUnSelectedItems.length > 0)
                await this.removeBound({
                    boundId: this._dto.id,
                    requestBody: this.newUnSelectedItems
                })

            if(this.newSelectedItems.length > 0)
                await this.setBound({
                    boundId: this._dto.id,
                    requestBody: this.newSelectedItems
                })

            this.defaultSelectedIds = this.defaultSelectedIds.filter(x => this.newUnSelectedItems.indexOf(x) == -1).concat(this.newSelectedItems)

            this.processing = false
        }
        catch {
            this.processing = false
        }
    }
}
