import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IExpanded, ILoad, ISelected} from "../../../../../../../../core";
import {QueryType, TypeOrgDto, TypeOrgsApi} from "../../../../../../../../services/management";

export class TypeOrganizationStore implements ILoad, IExpanded, ISelected {
    private _dto: TypeOrgDto;
    private _parent?: TypeOrganizationStore;
    _children?: TypeOrganizationStore[];
    loading: boolean = false;
    expand: boolean = false;
    private _selected: boolean = false;

    constructor(dto: TypeOrgDto, parent?: TypeOrganizationStore) {
        this._parent = parent;
        this._dto = dto;
        makeAutoObservable(this);
    }

    get id(): string {
        return this._dto.id
    }

    get name(): string {
        return this._dto.nameRus
    }

    get isEmpty(): boolean {
        return this._dto.parentId == this._dto.id;
    }

    get allChildren(): TypeOrganizationStore[] {
        let items: TypeOrganizationStore[] = [];
        this._children?.forEach(typeOrg => {
            items.push(typeOrg);
            items = items.concat(typeOrg.allChildren)
        })
        return items;
    }

    get selectedChildren() : TypeOrganizationStore[] {
        return this.selected ? [] : this.allChildren.filter(x => x._selected)
    }

    setSelected(value: boolean, down: boolean = true, up: boolean = true) {
        this._selected = value;
        if (this._selected) {
            if (up) {
                if (!this._parent?._selected && !this._parent?._children?.filter(x => !x._selected)[0]) {
                    this._parent?.setSelected(true, false, true)
                }
            }
            if (down) {
                this._children?.forEach(x => x.setSelected(false, true, false))
            }
        } else {
            if (down) {
                this._children?.forEach(x => x.setSelected(false, true, false))
            }
            if (up) {
                if (this._parent?.selected) {
                    this._parent?.setSelected(false, false, true)
                    this._parent._children?.filter(x => x.id != this.id)
                        .forEach(x => x.setSelected(true, false, false))
                }
            }
        }
    }

    get indeterminate(): boolean{
        if(!this._children?.length || this.selected){
            return false;
        }
        else {
            return (!this._selected && !!this._children.find(x => x.selected)) || !!this._children.find(x => x.indeterminate)
        }
    }

    get selected(): boolean {
        return this._selected || !!this._parent?.selected;
    }

    update(parent: TypeOrganizationStore, children: TypeOrganizationStore[]){
        this._parent = parent;
        this._children = children
    }

    async pull(): Promise<void> {
        this.loading = true;
        const api: TypeOrgsApi = new TypeOrgsApi(getApiConfiguration());
        const filters = {
            page: 1,
            size: 100,
            queryType: QueryType.Tree,
            parentId: this.id,
        };

        try {
            let items = await api.getTypeOrgs(filters);
            items = items?.filter(x => x.id !== this.id)
            this._children = items?.map(x => {
                let already = this._children?.filter(c => c.id == x.id)[0];
                return already ? already : new TypeOrganizationStore(x, this);
            });
        } catch (e) {
            this.loading = false;
        }
        this.loading = false;
    }

}
