import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IExpanded, ILoad, ISelected} from "../../../../../../../../core";
import {LocalitiesApi, LocalityDto, QueryType} from "../../../../../../../../services/management";

export class LocalityStore implements ILoad, IExpanded, ISelected {
    private _dto: LocalityDto;
    private _parent?: LocalityStore;
    _children?: LocalityStore[];
    loading: boolean = false;
    expand: boolean = false;
    private _selected: boolean = false;

    constructor(dto: LocalityDto, parent?: LocalityStore) {
        this._parent = parent;
        this._dto = dto;
        makeAutoObservable(this);
    }

    get id(): string{
        return this._dto.id
    }

    get name(): string{
        return this._dto.nameRus
    }

    get allChildren(): LocalityStore[] {
        let items: LocalityStore[] = [];
        this._children?.forEach(locality => {
            items.push(locality);
            items = items.concat(locality.allChildren)
        })
        return items;
    }

    get selectedChildren() : LocalityStore[] {
        return this.selected ? [] : this.allChildren.filter(x => x._selected)
    }

    setSelected(value: boolean, down: boolean = true, up: boolean = true) {
        this._selected = value;
        if (this._selected) {
            if (up) {
                if (!this._parent?._selected && !this._parent?._children?.filter(x => !x._selected)[0]) {
                    this._parent?.setSelected(true, false, true)
                }
            }
            if (down) {
                this._children?.forEach(x => x.setSelected(false, true, false))
            }
        } else {
            if (down) {
                this._children?.forEach(x => x.setSelected(false, true, false))
            }
            if (up) {
                if (this._parent?.selected) {
                    this._parent?.setSelected(false, false, true)
                    this._parent._children?.filter(x => x.id != this.id)
                        .forEach(x => x.setSelected(true, false, false))
                }
            }
        }
    }

    get indeterminate(): boolean{
        if(!this._children?.length || this.selected){
            return false;
        }
        else {
            return (!this._selected && !!this._children.find(x => x.selected)) || !!this._children.find(x => x.indeterminate)
        }
    }

    get selected(): boolean {
        return this._selected || !!this._parent?.selected;
    }

    update(parent: LocalityStore, children: LocalityStore[]){
        this._parent = parent;
        this._children = children
    }

    async pull(): Promise<void> {
        this.loading = true;
        const api: LocalitiesApi = new LocalitiesApi(getApiConfiguration());
        const filters = {
            page: 1,
            size: 100,
            queryType: QueryType.Tree,
            parentId: this.id,
        };

        try {
            const items = await api.getLocalities(filters);

            this._children = items.map(x => {
                let already = this._children?.filter(c => c.id == x.id)[0];
                return already ? already : new LocalityStore(x, this);
            });

            this.loading = false;
        } catch (e) {
            this.loading = false;
        }

    }

}
