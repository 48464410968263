import {getApiConfiguration} from "../../../../../../core";
import {BoundDto, ClinicCategoriesApi, ClinicCategoryDto,} from "../../../../../../services/management";
import {MappingsStore} from "./MappingsStore";
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import {MappingTableStore} from "./MappingTableStore";
import i18n from "i18next";
import {DefaultFormModalStore} from "../../../../../../components/shared/DefaultFormModalStore";
import {
    ClinicCategoryMappingFragment,
    ClinicCategoryMappingsQuery,
    ClinicCategoryMappingsQueryVariables,
    RemoveClinicCategoryMappingMutation,
    RemoveClinicCategoryMappingMutationVariables,
    SetClinicCategoryMappingMutation,
    SetClinicCategoryMappingMutationVariables
} from "../../../../../../gql/graphql";
import {getAppoloClient, GRAPHQL_URLS} from "../../../../../../graphql";
import {CLINIC_CATEGORY_MAPPING_QUERY} from "../../../../../../graphql/queries.graphql";
import {
    REMOVE_CLINIC_CATEGORY_MAPPING_MUTATION,
    SET_CLINIC_CATEGORY_MAPPING_MUTATION
} from "../../../../../../graphql/mutations.graphql";
import {jurisdictionId} from "../../../../../authorization/AuthorizationStore";

export class ClinicCategoryTableStore extends MappingTableStore<ClinicCategoryMappingFragment> {
    constructor(store: MappingsStore, dto: BoundDto) {
        super(store, dto, 'client-mappings-clinicCategory-store');

        this.creator.fields.entity.defaultSort = true;
        this.creator.fields.entity.required = true;
        this.creator.fields.value.required = true;
    }

    creator: DefaultFormModalStore<{ value: DefaultValueStore<string>; entity: DefaultSelectStore<any> }> =
        new DefaultFormModalStore({
            entity: new DefaultSelectStore<ClinicCategoryDto>(
                null, (x) => new ClinicCategoriesApi(getApiConfiguration()).getClinicCategories({
                    ...x,
                    orderBy: 'name_rus',
                    order: 'ascending'
                }),),
            value: new DefaultValueStore<string>("")
        }, (model) => this.create());

    async getMappings(filters: any): Promise<{ items: { item: ClinicCategoryMappingFragment; name: string }[]; count: number }> {
        const {data} = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).query<ClinicCategoryMappingsQuery, ClinicCategoryMappingsQueryVariables>({
            query: CLINIC_CATEGORY_MAPPING_QUERY, variables: filters,
        })

        return {
            items: (data.clinicCategoryMappings?.items ?? []).map(x => ({item: x, name: x.names?.nameRus ?? "" })),
            count: data.clinicCategoryMappings?.totalCount ?? 0
        }
    }

    async removeMapping(filter: { boundId: string; setMappingDto: Array<{ clientValue: string; entityId: string }> }): Promise<void> {
        for (let val of filter.setMappingDto) {
            await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<RemoveClinicCategoryMappingMutation, RemoveClinicCategoryMappingMutationVariables>({
                mutation: REMOVE_CLINIC_CATEGORY_MAPPING_MUTATION, variables: {
                    clientId: val.clientValue,
                    entityId: val.entityId,
                    jurisdictionId: jurisdictionId
                }
            })
        }
    }

    async setMapping(filter: { boundId: string; setMappingDto: Array<{ clientValue: string; entityId: string }> }): Promise<{ item: ClinicCategoryMappingFragment; name: string }[]> {
        const list: { item: ClinicCategoryMappingFragment; name: string }[] = [];
        for (let val of filter.setMappingDto) {
            const item = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<SetClinicCategoryMappingMutation, SetClinicCategoryMappingMutationVariables>({
                mutation: SET_CLINIC_CATEGORY_MAPPING_MUTATION, variables: {
                    entityId: val.entityId!,
                    clientValue: val.clientValue,
                    clientId: filter.boundId,
                    jurisdictionId: jurisdictionId
                }
            })
            list.push(({item: item.data?.setClinicCategoryMapping!, name: item.data?.setClinicCategoryMapping.names?.nameRus ?? ""}))
        }
        return list;
    }
}
