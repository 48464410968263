import {makeObservable, observable} from "mobx";
import {SpecialityTableStore} from "./SpecialityTableStore";
import {LocalityTableStore} from "./Locality/LocalityTableStore";
import {TypeOrganizationTableStore} from "./TypeOrg/TypeOrganizationTableStore";
import {ClientFragment} from "../../../../../../../gql/graphql";
import {ClientBoundStore} from "../ClientBoundStore";
import {JurisdictionDto} from "../../../../../../../services/auth";
import {DynamicalComponentStore} from "../../../../../../utils";

export class BoundsStore extends DynamicalComponentStore {
    readonly _store: ClientBoundStore;
    private _dto: ClientFragment;
    readonly id: string;

    specialityStore: SpecialityTableStore;
    typeOrganizationStore: TypeOrganizationTableStore;
    localityStore: LocalityTableStore;
    jurisdiction: JurisdictionDto;

    constructor(store: ClientBoundStore, dto: ClientFragment, jurisdiction: JurisdictionDto) {
        super()
        this._store = store;
        this._dto = dto;
        this.id = dto.id;
        this.jurisdiction = jurisdiction;

        this.specialityStore = new SpecialityTableStore(this, dto)
        this.typeOrganizationStore = new TypeOrganizationTableStore(this, dto)
        this.localityStore = new LocalityTableStore(this, dto)

        makeObservable(this, {
            specialityStore: observable,
            typeOrganizationStore: observable,
            localityStore: observable,
            jurisdiction: observable,
            id: observable,
        });
    }

    async updateState(): Promise<void> {
        switch (this.tabIndex) {
            case 0: {
                await this.localityStore.loadData()
                break
            }
            case 1: {
                await this.typeOrganizationStore.loadData()
                break
            }
            case 2: {
                await this.specialityStore.loadData()
                break
            }
        }
    }
}

