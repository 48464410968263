import {getObject, setObject, toDateTimeString} from '../../../core';
import {ClientTableStore} from "./ClientTableStore";
import {computed, makeObservable, observable} from "mobx";
import {ClientDetailsStore} from "./ClientDetailsStore";
import {ClientStore} from "./client/ClientStore";
import {ClientFragment} from "../../../gql/graphql";
import {DynamicalComponentStore} from "../../utils";

interface ClientViewModelLocalFilters {
    expand: boolean;
}

export class ClientItemStore extends DynamicalComponentStore {
    readonly _store: ClientTableStore;
    dto: ClientFragment;
    clientDetailsVM: ClientDetailsStore;
    clientStore: ClientStore;
    deleteDialogState: boolean = false;

    constructor(store: ClientTableStore, dto: ClientFragment) {
        super();
        this._store = store;
        this.dto = dto;
        this.clientDetailsVM = new ClientDetailsStore(this, dto);
        this.clientStore = new ClientStore(this, dto)
        const filters = getObject<ClientViewModelLocalFilters>(`client-${this.dto.id}`);

        if (filters) {
            this._expand = filters.expand;
        }
        makeObservable(this, {
            deleteDialogState: observable,
            name: computed,
            modifiedAt: computed,
            createdAt: computed,
            orgName: computed
        })
    }


    get name() {
        return this.dto.name;
    }

    get modifiedAt() {
        if (this.dto.modifiedAt) {
            return toDateTimeString(this.dto.modifiedAt);
        }
        return '';
    }

    get createdAt() {
        if (this.dto.createdAt) {
            return toDateTimeString(this.dto.createdAt);
        }
        return '';
    }

    get orgName() {
        return this.dto?.orgName
    }

    get userName() {
        return this.dto?.createdByUser?.name
    }

    update(dto: ClientFragment) {
        this.dto = dto;
        if (this.tabIndex == 0) {
            this.clientDetailsVM.update(dto)
        }
    }

    saveFilters() {
        setObject<ClientViewModelLocalFilters>(`client-${this.dto.id}`, {
            expand: this._expand,
        });
    }

    async updateState(): Promise<void> {
        await this.clientStore.updateState()
    }
}

