import {action, computed, makeObservable, observable} from "mobx";
import {DefaultSelectStore} from "../../../../components/shared/DefaultSelectStore";
import {getApiConfiguration} from "../../../../core";
import {OrganizationStatuses} from "../../../../core/const";
import i18n from "../../../../i18n";
import {OrganizationDoblesApi, OrganizationDto, OrganizationsApi} from "../../../../services/management";
import {PagedItems, TableStore} from "../../../utils";
import {OrganizationItemStore} from "../OrganizationItemStore";
import {DoubleItemStore} from "./DoubleItemStore";

export class DoubleTableStore extends TableStore<DoubleItemStore> {
    readonly store: OrganizationItemStore;
    antiDobleCheck: boolean = false;
    selectOrganization: DefaultSelectStore<OrganizationDto>;
    current: DoubleItemStore | undefined | null;

    constructor(store: OrganizationItemStore) {
        super();
        this.store = store;
        this.selectOrganization = new DefaultSelectStore(null,
            (filters) => new OrganizationsApi(getApiConfiguration()).getOrganizations(filters),
            (value) => this.checkValid(value));
        this.selectOrganization.validatorFuncAsync = this.validFn;
        this.selectOrganization.isSearchById = true;

        makeObservable(this, {
            current: observable,
            checkValid: action,
            validFn: action,
            childProcessing: computed
        });
    }

    checkValid(value: OrganizationDto | null): string {
        if (value?.id === this.store?.id) return i18n.t('errors.equalOrgId');
        if (this.items.find(x => x.id === value?.id)) return i18n.t('errors.dobleWasAdded').replace("dobleWasAdded", value?.id ?? '');
        if (value?.statusId === OrganizationStatuses.Double) return i18n.t('errors.cannotAddDouble');
        return ''
    }

    get childProcessing(): boolean {
        return !!this.items.find(x => x.processing);
    }

    async validFn(value: OrganizationDto | null | undefined): Promise<string> {
        if (!value) return "";
        const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
        const api_2: OrganizationDoblesApi = new OrganizationDoblesApi(getApiConfiguration());
        try {
            await api.getOrganizationById({ id: value?.id });
            const result = await api_2.getOrganizationDobles({ searchType: 'doble', searchId: value?.id });
            if (!!result.length) return i18n.t('errors.hasDobleId').replace("hasDobleId", value?.id);
            const result2 = await api_2.getOrganizationDobles({ searchType: 'antiDoble', searchId: value?.id });
            if (!!result2.length) return i18n.t('errors.hasAntiDobleId').replace("hasAntiDobleId", value?.id);
            return "";
        } catch (e) {
            return i18n.t('errors.wrongId').replace("wrongId", value?.id);
        }
    }

    async addDoubleToTable() {
        await this.selectOrganization.validate();
        if (!this.selectOrganization.value) return;
        if (!this.selectOrganization.valid) return;
        this.items = [new DoubleItemStore(this, { ...this.selectOrganization.value, doubleId: null, date: null }, true), ...this.items];
        this.selectOrganization.reset();
    }

    async request(): Promise<PagedItems<DoubleItemStore>> {
        const api: OrganizationDoblesApi = new OrganizationDoblesApi(getApiConfiguration());
        const newList: DoubleItemStore[] = this.items.filter(x => !x._dto.doubleId);
        const result = await api.getOrganizationDobles({ searchType: 'antiDoble', searchId: this.store?.id });
        const count = result.length ?? 0;
        for (const item of result) {
            newList.push(new DoubleItemStore(this, { ...item.organization, doubleId: item.id, date: item?.createdAt ?? null }));
        }
        this.antiDobleCheck = !!count;
        return new PagedItems<DoubleItemStore>(newList, count)
    };
}
