/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePersonDepJobDto
 */
export interface UpdatePersonDepJobDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonDepJobDto
     */
    postId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdatePersonDepJobDto
     */
    statusId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePersonDepJobDto
     */
    tags?: Array<string> | null;
}

export function UpdatePersonDepJobDtoFromJSON(json: any): UpdatePersonDepJobDto {
    return UpdatePersonDepJobDtoFromJSONTyped(json, false);
}

export function UpdatePersonDepJobDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePersonDepJobDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postId': !exists(json, 'postId') ? undefined : json['postId'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function UpdatePersonDepJobDtoToJSON(value?: UpdatePersonDepJobDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postId': value.postId,
        'statusId': value.statusId,
        'tags': value.tags,
    };
}

