import {getApiConfiguration, ISelected} from "../../../../../../../core";
import {BoundDto, ClientSpecialitiesApi, SpecialitiesApi,} from "../../../../../../../services/management";
import {BoundsStore} from "./BoundsStore";
import {BoundTableStore} from "./BoundTableStore";

export class SpecialityTableStore extends BoundTableStore {
    constructor(store: BoundsStore, dto: BoundDto) {
        super(store, dto, "client-bounds-speciality-store");
    }

    async getEntities(filters: any): Promise<{ items: (ISelected & { name: string; id: string })[]; count: number }> {
        const config = getApiConfiguration();
        config.headers!['Jurisdiction'] = this.store.jurisdiction.id!;
        const api: SpecialitiesApi = new SpecialitiesApi(config);
        const count = await api.getSpecialitiesCount(filters);
        const result = await api.getSpecialities(filters);

        return {
            items: result.map(x => ({id: x.id, name: x.nameRus, selected: false})),
            count: count.count
        }
    }

    async getSelectedIds(filters: any): Promise<string[]> {
        return (await new ClientSpecialitiesApi(getApiConfiguration()).getBoundSpecialities(filters)).map(x => x.specialityId);
    }

    async removeBound(filter: { boundId: string; requestBody: Array<string> }): Promise<void> {
        await new ClientSpecialitiesApi(getApiConfiguration()).removeBoundSpecialities(filter)
    }

    async setBound(filter: { boundId: string; requestBody: Array<string> }): Promise<void> {
        await new ClientSpecialitiesApi(getApiConfiguration()).setBoundSpecialities(filter)
    }
}
