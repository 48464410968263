import { graphql } from "../gql/gql"

export const SET_CLIENT_MUTATION = graphql(`
mutation SetClient(
  $clientId: String = null, 
  $description: String = null, 
  $name: String!,
  $orgName: String!,
  $secret: String = null,
  $userId: String!) {
    setClient(input: {
        clientId: $clientId, 
        description: $description,
        name: $name,
        orgName: $orgName,
        secret: $secret,
        userId: $userId
      }){
        id
      }
  }
  `)

export const REMOVE_BRICK_MAPPING_MUTATION = graphql(`
mutation RemoveBrickMapping(
  $clientId: String!, 
  $entityId: String!,
  $jurisdictionId: String!) {
    removeBrickMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)


export const REMOVE_CLINIC_CATEGORY_MAPPING_MUTATION = graphql(`
mutation RemoveClinicCategoryMapping(
  $clientId: String!, 
  $entityId: String!,
  $jurisdictionId: String!) {
    removeClinicCategoryMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)


export const REMOVE_LOCALITY_MAPPING_MUTATION = graphql(`
mutation RemoveLocalityMapping(
  $clientId: String!, 
  $entityId: String!,
  $jurisdictionId: String!) {
    removeLocalityMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)

export const REMOVE_ORGANIZATION_STATUS_MAPPING_MUTATION = graphql(`
mutation RemoveOrganizationStatusMapping(
  $clientId: String!, 
  $entityId: String!,
  $jurisdictionId: String!) {
    removeOrganizationStatusMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)


export const SET_BRICK_STATUS_MAPPING_MUTATION = graphql(`
mutation SetBrickMapping(
  $clientId: String!, 
  $entityId: String!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setBrickMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...BrickMapping
      }
  }
`)

export const SET_CLINIC_CATEGORY_MAPPING_MUTATION = graphql(`
mutation SetClinicCategoryMapping(
  $clientId: String!, 
  $entityId: String!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setClinicCategoryMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...ClinicCategoryMapping
      }
  }
`)

export const SET_LOCALITY_CATEGORY_MAPPING_MUTATION = graphql(`
mutation SetLocalityMapping(
  $clientId: String!, 
  $entityId: String!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setLocalityMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...LocalityMapping
      }
  }
`)

export const SET_ORGANIZATION_STATUS_MAPPING_MUTATION = graphql(`
mutation SetOrganizationStatusMapping(
  $clientId: String!, 
  $entityId: String!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setOrganizationStatusMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...OrganizationStatusMapping
      }
  }
`)


export const REMOVE_POST_MAPPING_MUTATION = graphql(`
mutation RemovePostMapping(
  $clientId: String!, 
  $entityId: String!,
  $jurisdictionId: String!) {
    removePostMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)


export const SET_POST_MAPPING_MUTATION = graphql(`
mutation SetPostMapping(
  $clientId: String!, 
  $entityId: String!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setPostMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...PostMapping
      }
  }
`)



export const REMOVE_RECORD_TYPE_MAPPING_MUTATION = graphql(`
mutation RemoveRecordTypeMapping(
  $clientId: String!, 
  $entityId: String!,
  $jurisdictionId: String!) {
    removeRecordTypeMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)


export const SET_RECORD_TYPE_MAPPING_MUTATION = graphql(`
mutation SetRecordTypeMapping(
  $clientId: String!, 
  $entityId: String!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setRecordTypeMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...RecordTypeMapping
      }
  }
`)


export const REMOVE_SPECIALITY_MAPPING_MUTATION = graphql(`
mutation RemoveSpecialityMapping(
  $clientId: String!, 
  $entityId: String!,
  $jurisdictionId: String!) {
    removeSpecialityMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)


export const SET_SPECIALITY_MAPPING_MUTATION = graphql(`
mutation SetSpecialityMapping(
  $clientId: String!, 
  $entityId: String!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setSpecialityMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...SpecialityMapping
      }
  }
`)


export const REMOVE_TYPE_LOCALITY_MAPPING_MUTATION = graphql(`
mutation RemoveTypeLocalityMapping(
  $clientId: String!, 
  $entityId: String!,
  $jurisdictionId: String!) {
    removeTypeLocalityMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)


export const SET_TYPE_LOCALITY_MAPPING_MUTATION = graphql(`
mutation SetTypeLocalityMapping(
  $clientId: String!, 
  $entityId: String!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setTypeLocalityMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...TypeLocalityMapping
      }
  }
`)



export const REMOVE_TYPE_ORG_MAPPING_MUTATION = graphql(`
mutation RemoveTypeOrgMapping(
  $clientId: String!, 
  $entityId: String!,
  $jurisdictionId: String!) {
    removeTypeOrgMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)


export const SET_TYPE_ORG_MAPPING_MUTATION = graphql(`
mutation SetTypeOrgMapping(
  $clientId: String!, 
  $entityId: String!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setTypeOrgMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...TypeOrgMapping
      }
  }
`)


export const REMOVE_PERSON_STATUS_MAPPING_MUTATION = graphql(`
mutation RemovePersonStatusMapping(
  $clientId: String!, 
  $entityId: Int!,
  $jurisdictionId: String!) {
    removePersonStatusMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)


export const SET_PERSON_STATUS_MAPPING_MUTATION = graphql(`
mutation SetPersonStatusMapping(
  $clientId: String!, 
  $entityId: Int!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setPersonStatusMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...PersonStatusMapping
      }
  }
`)


export const SET_PERSON_MAPPING_MUTATION = graphql(`
mutation SetPersonMapping(
  $clientId: String!, 
  $entityId: String!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setPersonMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...PersonMapping
      }
  }
`)

export const SET_ORGANIZATION_MAPPING_MUTATION = graphql(`
mutation SetOrganizationMapping(
  $clientId: String!, 
  $entityId: String!,
  $alternativeClientValue: String = null,
  $clientValue: String!,
  $entityDescription: String = null,
  $jurisdictionId: String!) {
    setOrganizationMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        alternativeClientValue: $alternativeClientValue,
        clientValue: $clientValue,
        entityDescription: $entityDescription,
        jurisdictionId: $jurisdictionId
      }){
        ...OrganizationMapping
      }
  }
`)


export const REMOVE_PERSON_MAPPING_MUTATION = graphql(`
mutation RemovePersonMapping(
  $clientId: String!, 
  $entityId: String!,
  $jurisdictionId: String!) {
    removePersonMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)

export const REMOVE_ORGANIZATION_MAPPING_MUTATION = graphql(`
mutation RemoveOrganizationMapping(
  $clientId: String!, 
  $entityId: String!,
  $jurisdictionId: String!) {
    removeOrganizationMapping(input: {
        clientId: $clientId, 
        entityId: $entityId,
        jurisdictionId: $jurisdictionId
      }){
        id
      }
  }
`)
