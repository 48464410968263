import {Box, Breadcrumbs, Card, Typography} from "@mui/material";
import {observer} from 'mobx-react';
import {useSnackbar} from "notistack";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {AuthorizePermission, PageTab, PageTabs} from '../../../components';
import {AllPermissions} from '../../../core/const';
import {useManagementStore} from "../../../stores";
import {Bounds} from "./tabs/bounds/Bounds";
import {Mappings} from "./tabs/mappings/Mappings";
import {JurisdictionsTab} from "./tabs/JurisdictionsTab";

export const Client = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const {id} = useParams<{ id: string }>();
    const {clientTableStore} = useManagementStore();
    const client = clientTableStore.current;
    const {t} = useTranslation();

    useEffect(() => {
        get();
    }, [id]);

    const get = async () => {
        try {
            if (id) {
                await clientTableStore.getClient(id);
            }
        } catch (e) {
            enqueueSnackbar(`Ошибка при загрузке человека`);
        }
    };

    const renderTabs = (index: number) => {
        if (client) {
            switch (index) {
                case 0:
                    return <Bounds store={client.clientStore.bounds}/>;
                case 1:
                    return <Mappings store={client.clientStore.mappings}/>;
                case 2:
                    return <JurisdictionsTab store={client.clientStore.jurisdictionsStore}/>;
                default:
                    return null;
            }
        }
    };


    return (
        <>

            <Box>
                {
                    client
                        ?
                        (<Breadcrumbs
                                separator="/"
                                sx={{mt: 1}}
                            >

                                <Link to="/clients" style={{textDecoration: 'none'}}>
                                    <Typography color="primary"
                                                variant="h6">{t('clientPage.client')}</Typography>
                                </Link>
                                <Typography color="textSecondary"
                                            variant="h6">
                                    {client.name}
                                </Typography>
                            </Breadcrumbs>
                        )

                        : '...'
                }
            </Box>
            <AuthorizePermission permissions={AllPermissions.client.read}>
                <Card
                    style={{display: 'flex', flexDirection: 'column'}}
                    sx={{
                        px: 2,
                        mt: 2
                    }}
                >
                    {
                        client
                            ? (
                                <>
                                    <PageTabs
                                        index={client.clientStore.tabIndex}
                                        onChange={t => client.clientStore.tabIndex = t}
                                    >
                                        <PageTab label={t('clientPage.tabs.bounds')} testid="client-bounds-tabs"/>
                                        <PageTab label={t('clientPage.tabs.mapping')} testid="client-mapping-tabs"/>
                                        <PageTab label={t('clientPage.tabs.jurisdictions')}
                                                 testid="client-mapping-jurisdictions"/>
                                    </PageTabs>

                                    <Box sx={{mt: 2, mb: 2}}>
                                        {renderTabs(client.clientStore.tabIndex)}
                                    </Box>
                                </>
                            )
                            : '...'
                    }
                </Card>
            </AuthorizePermission>
        </>
    );
});
